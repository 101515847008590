import React, { useEffect, useState } from "react";

import ExpertResumesLayout from "../../../components/ExpertResumesLayout/ExpertResumesLayout";
import Loader from "../../../components/Loader";
import Table from "../../../components/Table/Table";
import { getJobsRoutes } from "../../../handlers/navigationItems";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import ViewJob from "./ViewJob";

const columns = [
	{
		key: "jobTitle",
		label: "Job Title",
		icon: "account",
		editable: false,
		showProfile: true,
		image: "company.logo_url",
		imageClassName: "w-10",
		visible: true,
	},
	{ key: "jobStreetAddress", label: "Job Street Address", icon: "account", editable: false, visible: true },
	{ key: "employmentType", label: "Employment Type", icon: "account", editable: false, visible: true },
	{ key: "jobExpertise", label: "Job Expertise", icon: "account", editable: false, visible: true },
	{ key: "numberOfOpening", label: "Number Of Opening", icon: "account", editable: false, visible: true },
	{ key: "deadline", label: "Deadline Data", icon: "account", editable: false, visible: true },
	{ key: "jobStatus", label: "Job Status", icon: "account", editable: false, visible: true },
];
const statusOfJobs = { Draft: "Draft", Rejected: "Rejected", Posted: "Posted" };

function Jobs() {
	const jobService = new ResumeDataService();
	const [jobs, setJobs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState("");
	const [jobStatus, setJobsStatus] = useState("Draft"); // Draft, Posted
	const [filteredJobs, setFilteredJobs] = useState([]);
	const [searchValue] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [showAddJob] = useState(false);
	const [showProfile, setShowProfile] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	function onUpdate(key, value, index) {
		setSearchResults((prev) => {
			return prev.map((item, i) => {
				if (index === i) {
					return { ...item, [key]: value };
				}
				return item;
			});
		});
	}

	function sortByColumn(key, direction = "asc") {
		const sorted = searchResults.sort((a, b) => {
			const valueA = a[key] || "";
			const valueB = b[key] || "";

			if (direction === "asc") {
				return valueA.localeCompare(valueB);
			} else {
				return valueB.localeCompare(valueA);
			}
		});

		setSearchResults([...sorted]);
	}

	function getJobs() {
		setLoading(true);
		jobService.getJobs(
			(response) => {
				setLoading(false);
				setJobs(response.data);
			},
			(error) => {
				setLoading(false);
				setErrors(error);
			},
		);
	}

	useEffect(() => {
		getJobs();
	}, []);

	useEffect(() => {
		const data = jobs.filter((job) => job.jobStatus === jobStatus);
		setFilteredJobs(data);
	}, [jobStatus, jobs]);

	useEffect(() => {
		const results = filteredJobs.filter(
			(job) =>
				job.jobTitle.toLowerCase().includes(searchValue.toLowerCase()) ||
				job.companyName.toLowerCase().includes(searchValue.toLowerCase()),
		);
		setSearchResults(results);
	}, [jobs, filteredJobs, searchValue]);

	return (
		<ExpertResumesLayout navItems={getJobsRoutes()} headingTitle="Jobs Listing" headingIcon="arrow-left">
			<div className="pl-4 my-resumes-section">
				{loading ? (
					<Loader />
				) : (
					<>
						{errors && (
							<div className="alert alert-danger" role="alert">
								{errors}
							</div>
						)}
						<div className="row px-3">
							<div className="w-full">
								<Table
									data={searchResults}
									columns={columns}
									onUpdate={onUpdate}
									sortByColumn={sortByColumn}
									showAddForm={showAddJob}
									showProfile={showProfile}
									ProfileComponent={ViewJob}
									setShowProfile={setShowProfile}
									setSelectedItem={setSelectedJob}
									selectedItem={selectedJob}
									tabs={[
										{
											name: `Draft (${jobs.filter((job) => job.jobStatus === statusOfJobs.Draft).length})`,
											key: statusOfJobs.Draft,
										},
										{
											name: `Rejected (${jobs.filter((job) => job.jobStatus === statusOfJobs.Rejected).length})`,
											key: statusOfJobs.Rejected,
										},
										{
											name: `Published (${jobs.filter((job) => job.jobStatus === statusOfJobs.Posted).length})`,
											key: statusOfJobs.Posted,
										},
									]}
									currentTab={jobStatus}
									setCurrentTab={setJobsStatus}
									// onSubmit={onSubmit}
									fetchData={getJobs}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</ExpertResumesLayout>
	);
}

export default Jobs;
