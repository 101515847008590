import { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";

const ManageStylesheets = () => {
	const location = useLocation();

	useEffect(() => {
		const attributes = Array.from(document.getElementsByTagName("link")).filter(
			(item) => item.attributes.rel?.value === "stylesheet",
		);

		const baseUrl = window.location.origin;

		const excludeLinks = [
			"/",
			"/jobs",
			"/companies",
			"/companies/:id",
			"/jobs/:id",
			"/profile",
			"/register",
			"/register/create-account",
			"/signin",
			"/creating-profile",
		];
		const currentPath = location.pathname;

		// use matchPath to check if the current path is a match
		const isExcluded = excludeLinks.some((path) => matchPath(path, currentPath));

		if (isExcluded) {
			// Remove stylesheets with href starting with thisBaseURL
			attributes.forEach((item) => {
				if (item.href.startsWith(baseUrl) && !item.href.includes("/static/")) {
					item.href = item.href + "TO_BE_REMOVED";
				}
			});
		} else {
			// Add back the stylesheets
			attributes.forEach((item) => {
				if (item.href.endsWith("TO_BE_REMOVED")) {
					item.href = item.href.replaceAll("TO_BE_REMOVED", "");
				}
			});
		}
	}, [location]); // Re-run effect on route changes

	return null; // This component doesn't render anything
};

export default ManageStylesheets;
