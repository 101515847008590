/* eslint-disable no-useless-escape */
import { GoogleLogin } from "@react-oauth/google";
import { CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { decodeToken } from "react-jwt";
import { useNavigate } from "react-router-dom";

import poolData from "../../../../userpool";
import ErrorToast from "../../../components/ErrorToast";
import Spinner from "../../../components/Spinner";
import AuthContext from "../../../context/Auth/AuthContext";
import ResumeContext from "../../../context/ResumeContext";
import { isValidEmail, isValidPassword } from "../../../handlers/validationFunctions";
import { StaticValue } from "../../../seed/constants";

const userPool = new CognitoUserPool(poolData);
const staticValue = new StaticValue();
const staticValues = staticValue.getDeafaulValues();

const referer = new URLSearchParams(window.location.search).get("ref");

function JobSeekerRegistrationForm({ personalInfo }) {
	const { setUserData } = useContext(AuthContext);

	const context = useContext(ResumeContext);

	const navigate = useNavigate();

	const [registrationData, setRegistrationData] = useState({
		email: personalInfo.email,
		password: "",
		confirm_password: "",
	});

	useEffect(() => {
		if (personalInfo.email) {
			setRegistrationData({
				...registrationData,
				email: personalInfo.email,
			});
		}
	}, [personalInfo]);

	const { email, password, confirm_password } = useMemo(() => registrationData, [registrationData]);

	const [errors, setErrors] = useState({
		email: "",
		confirm_password: "",
		password: "",
	});

	const [serverError, setServerError] = useState("");

	function onChange(event) {
		setRegistrationData({
			...registrationData,
			[event.target.name]: event.target.value,
		});
		setErrors({
			email: "",
			confirm_password: "",
			password: "",
		});
	}

	const [loading, setLoading] = useState(false);
	useEffect(() => {}, [loading]);

	function handleSubmit(event) {
		event.preventDefault();
		setErrors({
			email: "",
			confirm_password: "",
			password: "",
		});

		setServerError("");
		setLoading(true);

		if (email === "" || password === "" || confirm_password === "") {
			setErrors({
				email: email === "" ? "Email is required" : "",
				password: password === "" ? "Password required" : "",
				confirm_password: confirm_password === "" ? "Confirm password is required" : "",
			});
			setLoading(false);
			return;
		}
		if (!isValidPassword(password)) {
			setErrors((prevState) => {
				return {
					...prevState,
					password:
						"<ul><li>Password must have least 1 number</li><li>Password must have least 1 special character(^ $ *)</li><li>Password must have at least 1 uppercase letter</li><li>Password must have at least 1 lowercase letter</li></ul>",
				};
			});
			setLoading(false);
			return;
		}

		if (password !== confirm_password) {
			setErrors((prevState) => {
				return {
					...prevState,
					confirm_password: "Password and confirm password should match",
				};
			});
			setLoading(false);
			return;
		}

		if (email && !isValidEmail(email)) {
			setErrors((prevState) => {
				return {
					...prevState,
					email: "Please enter a valid email address",
				};
			});
			setLoading(false);
			return;
		}

		const attributeList = [];
		attributeList.push(
			new CognitoUserAttribute({
				Name: "email",
				Value: email,
			}),
		);
		userPool.signUp(email, password, attributeList, null, (err) => {
			if (err) {
				if ({ ...err }["code"] === "UsernameExistsException") {
					setServerError("Email already exists");
				}
			} else {
				const goTo = referer && referer.includes("expert-help") ? "/signin?ref=" + referer : "/creating-profile";
				navigate(goTo, {
					state: {
						email: email,
						message: `Registered Successfully, Please follow the link sent to your email (${email}) to
							activate your DProz account, once your account is activated, you can log on to DProz and start applying
							for jobs on any device.`,
					},
				});
			}
			setLoading(false);
		});
	}

	useEffect(() => {
		if (context) {
			let { contactInfo } = context.resumeData;
			setRegistrationData({
				...registrationData,
				email: contactInfo.email,
			});
		}
	}, [context]);

	const [passwordValidationState, setPasswordValidationState] = useState({
		length: false,
		uppercase: false,
		number: false,
		specialCharacter: false,
		match: false,
	});

	useEffect(() => {
		setPasswordValidationState({
			length: password.length >= 8,
			uppercase: /[A-Z]/.test(password),
			number: /\d/.test(password),
			specialCharacter: /[!@#$%^&*()_+\-=\[\]/{};&#39;:"\\|,.<>\/?]/.test(password),
			match: password.length > 0 && password === confirm_password,
		});
	}, [password, confirm_password]);

	const [, setCookie, removeCookie] = useCookies(["resume"]);

	function onGoogleSignIn(googleUser) {
		const credential = `GO::${googleUser.credential}`;

		const expireDate = new Date();
		expireDate.setDate(expireDate.getDate() + 30);
		setCookie("Authorization", credential, {
			path: "/",
			expires: expireDate,
		});

		if (googleUser.credential != null) {
			removeCookie(staticValues.localStorageResumeId);
			localStorage.removeItem("resumeList");
			setLoading(false);
			const decodedToken = decodeToken(googleUser.credential);

			const data = {
				email: decodedToken.email,
				firstName: decodedToken.given_name,
				lastName: decodedToken.family_name,
				picture: decodedToken.picture,
			};

			setUserData(data);

			navigate(referer && referer.includes("expert-help") ? "/expert-help/personal-info" : "/creating-profile");
		}
	}

	const [showEmailOptions, setShowEmailOptions] = useState(false);

	const buttonRef = useRef();

	const buttonWidth = useMemo(() => {
		if (buttonRef.current) {
			return buttonRef.current.offsetWidth;
		}
		return 0;
	}, [buttonRef.current]);

	return (
		<>
			<div className="mb-5 py-2 flex items-center justify-center" ref={buttonRef}>
				<GoogleLogin
					onSuccess={onGoogleSignIn}
					onFailure={() => toast.error("Login Failed")}
					size="large"
					width={`${buttonWidth}px`}
					text="continue_with"
					useOneTap
				/>
			</div>
			<div className="mb-5 text-center">
				<p className="text-xs font-medium">OR</p>
			</div>
			{serverError !== "" && <ErrorToast error={serverError} />}{" "}
			{showEmailOptions ? (
				<form className="tr-form">
					<div className="flex flex-col gap-1 mb-4">
						<input
							type="email"
							className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
							placeholder="Enter your email address"
							value={personalInfo.email}
							onChange={onChange}
							name="email"
							disabled
						/>
						{errors.email && <p className="text-sm text-error-default">{errors.email}</p>}
					</div>
					<div className="flex flex-col gap-1 mb-4">
						<input
							type="password"
							className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
							placeholder="Create a password"
							onChange={onChange}
							value={password}
							name="password"
						/>
						{errors.password && <p className="text-sm text-error-default">{errors.password}</p>}
					</div>
					{password.length > 0 && (
						<>
							<div className="flex flex-col gap-1 mb-4">
								<input
									type="password"
									className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
									placeholder="Confirm Password"
									onChange={onChange}
									value={confirm_password}
									name="confirm_password"
								/>
								{errors.confirm_password && <p className="text-sm text-error-default">{errors.confirm_password}</p>}
							</div>
							<div className="flex flex-col gap-1 mb-4 bg-gray-50 py-3 rounded shadow-sm">
								<ul className="pl-8 list-disc">
									<li className={passwordValidationState.length ? "text-success-default" : "text-error-default"}>
										Password is at least 6 characters long.
									</li>
									<li className={passwordValidationState.number ? "text-success-default" : "text-error-default"}>
										Password has at least one number
									</li>
									<li
										className={passwordValidationState.specialCharacter ? "text-success-default" : "text-error-default"}
									>
										Password has at least one special character
									</li>
									<li className={passwordValidationState.uppercase ? "text-success-default" : "text-error-default"}>
										Password has at least one uppercase letter
									</li>
									<li className={passwordValidationState.match ? "text-success-default" : "text-error-default"}>
										Password and confirm password match
									</li>
								</ul>
							</div>
						</>
					)}
					<button
						type="button"
						className="w-full rounded-md bg-primary-700 text-white font-inter text-base py-2 flex items-center justify-center"
						onClick={handleSubmit}
					>
						{loading ? <Spinner /> : "Sign Up"}
					</button>
				</form>
			) : (
				<button
					className="w-full rounded-md hover:bg-gray-100 border border-gray-500 text-gray-700 font-inter text-base py-2 flex items-center justify-center"
					onClick={() => setShowEmailOptions(true)}
				>
					Continue with Email
				</button>
			)}
		</>
	);
}

export default JobSeekerRegistrationForm;
