import React from "react";

import Icon from "./Icon";

function Spinner() {
	return (
		<div className="animate-spin">
			<Icon iconName="loading" size={18} />
		</div>
	);
}

export default Spinner;
