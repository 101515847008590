import React, { useState } from "react";
import { Link } from "react-router-dom";

import { BuildingIcon, CheckIcon, GlobeIcon, MapPinIcon, UsersIcon } from "../../../assets/icons/interface/interface";
import Drawer from "../../../components/Drawer/Drawer";
import ViewJob from "../../StaticPages/HomePage/components/ViewJob";
import { Card } from "./Card";
import JobListingItem from "./JobListingItem";

function Jobs({ jobs }) {
	const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	return (
		<>
			<Drawer
				isOpen={showJobDetailsModal}
				onClose={() => {
					setShowJobDetailsModal(false);
				}}
			>
				<ViewJob data={selectedJob} setData={setSelectedJob} setShowJobDetailsModal={setShowJobDetailsModal} />
			</Drawer>
			<div className="space-y-6">
				{jobs.map((company, index) => (
					<Card key={index} className="w-full">
						<div className="p-4">
							<div className="flex items-center mb-3">
								<Link to={`/companies/${company?.company}`} state={company}>
									<img
										alt={`${company?.companyDetails?.company_name || company.jobs[0]?.companyName} logo`}
										loading="lazy"
										width="64"
										height="64"
										decoding="async"
										data-nimg="1"
										className="rounded mr-4 hover:opacity-80 transition-opacity duration-200"
										src={
											company?.companyDetails
												? company?.companyDetails?.logo_url
												: "https://picsum.photos/200?random=" + index
										}
										onError={(e) => (e.target.src = "/images/co-placeholder.png")}
									/>
								</Link>
								<div>
									<Link to={`/companies/${company?.company}`} state={company}>
										<h3 className="text-xl font-bold text-gray-900 font-plus-jakarta hover:text-gray-700 transition-colors duration-200">
											{company?.companyDetails?.company_name || company.jobs[0]?.companyName}
										</h3>
									</Link>
									<p className="text-sm text-gray-700 font-inter mt-1">
										{company?.companyDetails ? company?.companyDetails?.company_industry : ""}
									</p>
								</div>
							</div>
							<div className="flex flex-wrap gap-2 mt-3">
								{company?.companyDetails && (
									<span className="flex items-center bg-gray-100 text-gray-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
										<BuildingIcon className="w-3 h-3 mr-1 text-gray-500" />
										{company?.companyDetails?.company_industry}
									</span>
								)}
								{company?.companyDetails && (
									<span className="flex items-center bg-gray-100 text-gray-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
										<UsersIcon className="w-3 h-3 mr-1 text-gray-500" />
										{company?.companyDetails?.company_size_approx} employees
									</span>
								)}

								{company?.jobs?.[0] && (
									<span className="flex items-center bg-gray-100 text-gray-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
										<MapPinIcon className="w-3 h-3 mr-1 text-gray-500" />
										{company?.jobs?.[0]?.jobLocation?.city}, {company?.jobs?.[0]?.jobLocation?.country}
									</span>
								)}

								{company?.companyDetails && (
									<span className="flex items-center bg-gray-100 text-gray-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
										<GlobeIcon className="w-3 h-3 mr-1 text-gray-500" />
										<a
											href={company?.companyDetails?.company_website || company?.companyDetails?.linkedin_url}
											target="_blank"
											rel="noopener noreferrer"
											className="hover:underline text-gray-700"
										>
											Website
										</a>
									</span>
								)}
								<span className="flex items-center bg-green-100 text-green-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
									<CheckIcon className="w-3 h-3 mr-1 text-green-600" />
									Actively Hiring
								</span>
							</div>
						</div>
						<div className="border-t border-gray-200" /> {/* Added light separator */}
						<div className="px-4 py-2">
							<div className="divide-y divide-gray-200">
								{company?.jobs?.map((job, jobIndex) => {
									return (
										<JobListingItem
											key={jobIndex}
											job={{
												...job,
												logo: undefined,
											}}
											showCompany={false}
											onClick={() => {
												setSelectedJob(job);
												setShowJobDetailsModal(true);
											}}
											onApply={() => {
												setSelectedJob(job);
												setShowJobDetailsModal(true);
											}}
											titleClassName="text-sm font-semibold"
										/>
									);
								})}
							</div>
						</div>
					</Card>
				))}
			</div>
		</>
	);
}

export default Jobs;
