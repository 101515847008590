import React from "react";
import { Link } from "react-router-dom";

import EmbededVideo from "../../components/EmbededVideo";
import SocialShare from "../../components/SocialShare";
import useIsLoggedIn from "../../hooks/use-IsLoggedIn";
import Header from "../Profile/components/Header";
import Footer from "../StaticPages/Footer/Footer";

const challenges = [
	{
		title: "Information and Knowledge:",
		challenges: [
			"Lack of access to information.",
			"Mismatch between education and opportunities.",
			"Lack of knowledge about job opportunities.",
			"Lack of knowledge about what skills are in demand.",
		],
	},
	{
		title: "Financial Barriers:",
		challenges: ["Insufficient financial support.", "High competition for limited financial resources."],
	},
	{
		title: "Networking and Connection:",
		challenges: [
			"Lack of connection to social networks.",
			"Lack of connection with people in the job market.",
			"Lack of networking and connection to employees.",
		],
	},
	{
		title: "Experience and Competence:",
		challenges: [
			"Lack of experience.",
			"Lack of competence in computer skills.",
			"Lack of adequate experience in the corporate world.",
			"Lack of practical training in the workplace.",
			"Lack of experience on a particular job.",
			"Lack of experience with the job search process.",
		],
	},
	{
		title: "Confidence and Perception:",
		challenges: [
			"Lack of confidence.",
			"Lack of trust.",
			"Fear of failure.",
			"Negative perception towards graduates.",
			"Lack of confidence among graduates.",
			"Fear to try.",
		],
	},
	{
		title: "Education and Skills:",
		challenges: [
			"Mismatch between education and opportunities.",
			"Lack of some required skills.",
			"Lack of expertise highly needed in the job market.",
			"Lack of competencies in specific areas.",
		],
	},
	{
		title: "Environmental Factors:",
		challenges: [
			"Environmental factors affecting job search.",
			"Environmental conditions influencing job opportunities.",
			"Poor teaching facilities.",
			"Lack of a good environment for graduates to seek jobs.",
		],
	},
	{
		title: "Competition and Job Market Dynamics:",
		challenges: [
			"High competition among graduates.",
			"Competitive nature of the industry.",
			"Pressure to try everything.",
			"Inefficiencies in the job market.",
			"Insufficient job needs.",
			"Unavailability of employment opportunities.",
		],
	},
	{
		title: "Personal Development:",
		challenges: [
			"Lack of commitment.",
			"Discouragement from other graduates.",
			"Negative perspective on employment criteria.",
			"Lack of management and leadership skills.",
			"Lack of skills necessary for personal development.",
		],
	},
	{
		title: " Other Factors:",
		challenges: [
			"Lack of information about what the industry requires.",
			"Poor communication skills.",
			"Lack of right internship.",
			"Lack of proper career guidance.",
			"Lack of direction in job search.",
			"Unpaid internships.",
			"Lack of support from parents.",
			"Peer pressure",
		],
	},
];

function GraduateChallenges() {
	const isLoggedIn = useIsLoggedIn();
	document.title = "Unlocking Opportunities: Bridging the Gap Between Job Seekers and Employers in Tanzania";
	const screenWidth = window.screen.width;

	return (
		<>
			<div className="font-content font-articles">
				<Header isLoggedIn={isLoggedIn} />
				<section className="py-3 bg-light home-banner home-banner-employer">
					<div className="container my-2 p-1">
						<div className="">
							<nav aria-label="breadcrumb mb-0 fs-14">
								<ol className="breadcrumb bg-transparent px-0">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									<li className="breadcrumb-item">
										<Link to="preparation-center.html">Blog</Link>
									</li>
									<li className="breadcrumb-item active">Job Hunt Challenges</li>
								</ol>
							</nav>
							<h2 className="home-h3 fw-normal mt-2 p-1" id="intro">
								Bridging the Gap Between Job Seekers and Employers
							</h2>
							{screenWidth < 768 && (
								<div>
									<SocialShare />
								</div>
							)}
						</div>
					</div>
				</section>
				<section className="py-5 bg-white container p-1">
					<div className="row">
						<div className="col-md-9 p-1" data-spy="scroll" data-target="#list-example" data-offset="0">
							<div className="container">
								<p className="mb-4">
									Are you or someone you know frustrated by the challenges of finding the right job or qualified
									candidates in Tanzania&#39;s formal sector? You&#39;re not alone. Despite a pool of
									<a
										href="https://www.linkedin.com/feed/update/urn:li:activity:7146572746766843904"
										target="_blank"
										rel="noopener noreferrer"
									>
										talented individuals
									</a>{" "}
									actively seeking employment,{" "}
									<a
										href="https://www.linkedin.com/feed/update/urn:li:activity:7145587916734443521"
										target="_blank"
										rel="noreferrer"
									>
										a staggering 40% of jobs remain open annually
									</a>
									.
								</p>

								<p className="mb-4">
									Our survey of employers (hiring managers & HR professionals) revealed a shocking disparity in the
									volume of applications they receive. 80% of employers were actively hiring, with 61% receiving fewer
									than 50 applications, 27% receiving between 50 and 100 applications, and 12% receiving an overwhelming
									300 to 500 applications, with some reporting up to 1,500 applications.
								</p>

								<p className="mb-4">
									To make sense of this applications disparity, we surveyed hundreds of job seekers between 2021 and
									2022. The majority of those actively engaged in the job search identified several challenges and pain
									points:
								</p>

								<ul className="mb-4 pl-4">
									<li>
										<strong>Lack of Information:</strong> Job seekers struggle with limited access to information about
										job opportunities.
									</li>
									<li>
										<strong>Knowledge Gap:</strong> There&#39;s often a mismatch between job opportunities and the
										required skills and experience.
									</li>
									<li>
										<strong>Social and Job Market Connections:</strong> Many lack the necessary connections to navigate
										the job market successfully.
									</li>
									<li>
										<strong>High Competition:</strong> Faced with intense competition, job seekers feel pressured to
										apply to everything, leading to inefficiencies in the job market.
									</li>
								</ul>
								<section className="py-5 bg-primary mb-4" id="challenges">
									<div className="container text-center">
										<h4 className="mb-4 text-white font-weight-normal">
											Read a detailed breakdown of the challenges job seekers face below. Their stories are both
											gut-wrenching and heartbreaking, emphasizing the urgent need for collective action.
										</h4>
										<h4 className="text-white font-weight-normal">
											The first step toward being part of the solution is understanding these issues and bringing the
											right level of empathy to the situation.
										</h4>
									</div>
								</section>

								<h3 className="home-h4 mb-3">Navigating Job Search in Tanzania: Graduates Share Common Challenges</h3>

								<div className="row mb-5">
									{challenges.map((challenge, index) => (
										<div className="col-md-6 mb-4" key={index}>
											<h4 className="home-h5 mb-2">{`${index + 1}. ${challenge.title}`}</h4>
											<ul className="mb-0 pl-4">
												{challenge.challenges.map((item, index) => (
													<li key={index}>{item}</li>
												))}
											</ul>
										</div>
									))}
								</div>

								<p className="mb-4">
									It&#39;s essential to recognize that individual circumstances vary, and challenges are interconnected.
									Overcoming these challenges often requires a holistic approach, including personal development,
									skill-building, networking, and adapting to evolving market dynamics.
								</p>

								<section className="py-5 bg-primary mb-4" id="solution">
									<div className="container text-center text-white">
										<h5 className="text-white font-weight-normal">
											Explore how we can bridge this gap and create a more equitable job market for everyone. Together,
											let&#39;s unlock opportunities and shape a brighter future for Tanzania&#39;s workforce. Join Us
										</h5>
									</div>
								</section>

								<h3 className="home-h4 mb-3">The Solution: Empowering Job Seekers with a Holistic Approach</h3>

								<p className="mb-4">
									Recognizing the varied circumstances and interconnected challenges individuals face, overcoming them
									demands a holistic strategy. This includes personal development, skill-building, networking, and
									adapting to market dynamics.
								</p>
								<p className="mb-4">
									Our empathy and commitment to being part of the solution with a holistic approach led us to create
									Dproz — a guide to empower job seekers. Meeting them wherever they are, helping them prepare, and
									connecting them to the right job opportunities aligned with their skills and experience.
								</p>
								<p className="mb-4">
									Staying true to this holistic approach humbly reminds us that we can&#39;t do this alone. We need
									everyone to join forces to shape a brighter and more equitable future for Tanzania&#39;s workforce.
									So, how can you help?
								</p>
								<div className="">
									<div className="card card-body mb-4">
										<h4 className="home-h5 mb-2">1. Spot an Opportunity, Share It:</h4>

										<ul className="mb-0">
											<li>
												Majority of employers receive fewer than 50 applicants, echoing job seekers&#39; concerns about
												lacking access to job information.
											</li>
											<li>Dproz makes it easy for anyone to share job opportunities with other job seekers.</li>
											<li>By sharing relevant opportunities, we unlock the collective power to help one another.</li>
										</ul>
									</div>
								</div>

								<div className="card card-body mb-4">
									<h4 className="home-h5 mb-2">2. Trust Your Network to Stand Out:</h4>

									<ul className="mb-4">
										<li>
											Employers spend a few minutes scanning resumes, and many candidates&#39; resumes lack key
											information.
										</li>
										<li>
											Dproz allows job seekers to build professional resumes for free using expert-crafted templates and
											content suggestions.
										</li>
										<li>
											Leverage a network of trusted individuals, such as referees, to review and provide private
											suggestions to help improve resumes on the platform.
										</li>
										<li>This unlocks the power of our network to assist each other in standing out.</li>
									</ul>
									<EmbededVideo videoLink="https://www.youtube.com/watch?v=c9L1ZEWcJkY" />
								</div>

								<div className="card card-body mb-4">
									<h4 className="home-h5 mb-2">3. Target Quality Jobs, Not Quantity:</h4>

									<ul className="mb-4">
										<li>
											Many job seekers lack access to job information and face a knowledge gap between job demands and
											their skills.
										</li>
										<li>
											Dproz recommends relevant jobs based on resumes and job preferences, providing fit scores and a
											breakdown of experience, education, and skills.
										</li>
										<li>
											itThis approach guides job seekers to focus on quality jobs, reducing the volume of irrelevant
											applications and improving the chances of meaningful connections.
										</li>
									</ul>
									<EmbededVideo videoLink="https://www.youtube.com/watch?v=sLZCPuJq630" />
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<ul className="list-group sticky mb-3">
								<li className="list-group-item list-group-item-action">
									<a href="#intro">Introduction</a>
								</li>
								<li className="list-group-item list-group-item-action">
									<a href="#challenges">Challenges</a>
								</li>
								<li className="list-group-item list-group-item-action">
									<a href="#solution">The Solution</a>
								</li>
							</ul>
							<SocialShare />
						</div>
					</div>
				</section>

				<section className="py-5 bg-primary">
					<div className="container text-center text-white">
						<h2 className="text-white mb-3">Job hunting in Tanzania?</h2>
						<h4 className="mb-4 text-white font-weight-normal">
							Build your resume on Dproz, seek feedback from trusted connections to enhance it, and set your job
							preferences. Let&#39;s find that perfect match for you!
						</h4>
						<Link
							to="/resume/select-experience"
							className="home-btn btn home-btn-sm bg-white text-primary font-weight-normal"
						>
							Create Resume
						</Link>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default GraduateChallenges;
