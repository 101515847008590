import React from "react";

function Resources() {
	return (
		<section className="bg-white py-12 px-4 md:px-8">
			<div className="max-w-6xl mx-auto">
				<h2 className="text-3xl font-bold mb-8 text-gray-900">Job Seeker Resources</h2>
				<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
					<div className="bg-white p-6 rounded-lg shadow-md">
						<h3 className="text-xl font-semibold mb-2">Resume Writing Tips</h3>
						<p className="text-gray-600 mb-4">
							Learn how to create a standout resume that catches employers&#39; attention.
						</p>
						<a
							className="text-primary-600 hover:text-primary-700 font-medium text-sm inline-flex items-center group"
							href="/resume-tips"
						>
							Learn More
							<span className="transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1 ml-1">
								›
							</span>
						</a>
					</div>
					<div className="bg-white p-6 rounded-lg shadow-md">
						<h3 className="text-xl font-semibold mb-2">Interview Preparation Guide</h3>
						<p className="text-gray-600 mb-4">Prepare for your next interview with our comprehensive guide.</p>
						<a
							className="text-primary-600 hover:text-primary-700 font-medium text-sm inline-flex items-center group"
							href="/resources/interview-prep"
						>
							Learn More
							<span className="transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1 ml-1">
								›
							</span>
						</a>
					</div>
					<div className="bg-white p-6 rounded-lg shadow-md">
						<h3 className="text-xl font-semibold mb-2">Career Development Courses</h3>
						<p className="text-gray-600 mb-4">Enhance your skills with our free online career development courses.</p>
						<a
							className="text-primary-600 hover:text-primary-700 font-medium text-sm inline-flex items-center group"
							href="/resources/career-courses"
						>
							Learn More
							<span className="transition-transform duration-300 transform translate-x-0 group-hover:translate-x-1 ml-1">
								›
							</span>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Resources;
