import React from "react";

function SocialShare({ message, onClick }) {
	const title = document.title;
	const link = window.location.href;
	const shareString = message ? message : `An interesting read, ${title} at ${link}`;
	return (
		<div className="flex justify-center p-3 items-center">
			<div className="mr-2" onClick={() => onClick && onClick()}>
				<a
					href={`https://www.facebook.com/sharer/sharer.php?u=${link}&src=sdkpreparse&quote=${shareString}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fab fa-facebook-square fa-2x" />
				</a>
			</div>
			<div className="mr-2" onClick={() => onClick && onClick()}>
				<a href={`https://twitter.com/intent/tweet?url=${shareString}`} target="_blank" rel="noopener noreferrer">
					<i className="fa-brands fa-x-twitter text-dark fa-2x" />
				</a>
			</div>
			<div className="mr-2" onClick={() => onClick && onClick()}>
				<a
					href={`https://www.linkedin.com/sharing/share-offsite/?url=${link}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<i className="fab fa-linkedin fa-2x" />
				</a>
			</div>
			<div className="mr-2" onClick={() => onClick && onClick()}>
				<a href={`https://wa.me/?text=${shareString}`} target="_blank" rel="noopener noreferrer">
					<i className="fab fa-whatsapp-square fa-2x text-green-500" />
				</a>
			</div>
		</div>
	);
}

export default SocialShare;
