import React, { useState } from "react";

import { DownloadIcon, FileIcon, HelpCircle, Trash, Upload } from "../../../assets/icons/interface/interface";
import { Alert, AlertDescription, AlertTitle } from "../../../components/Alert/Alert";
import { Badge } from "../../../components/Badge/Badge";
import { Label } from "../../../components/Label/Label";
import { Button } from "../../JobsPage/components/Button";
import { Card, CardContent } from "../../JobsPage/components/Card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../JobsPage/components/Select";

export default function DocumentCenter() {
	const [documents, setDocuments] = useState([
		{
			id: 1,
			name: "John_Doe_Resume_2024.pdf",
			type: "Resume",
			uploadDate: "2024-11-01",
			size: "892 KB",
		},
		{
			id: 2,
			name: "Cover_Letter_TechCorp.pdf",
			type: "Cover Letter",
			uploadDate: "2024-11-05",
			size: "445 KB",
		},
		{
			id: 3,
			name: "Portfolio_2024.pdf",
			type: "Portfolio",
			uploadDate: "2024-11-08",
			size: "2.4 MB",
		},
	]);

	const documentTypes = [
		"Resume",
		"Cover Letter",
		"Portfolio",
		"Certification",
		"Reference Letter",
		"Additional Document",
	];

	const deleteDocument = (docId) => {
		setDocuments(documents.filter((doc) => doc.id !== docId));
	};

	return (
		<div className="w-full mt-8">
			<h2 className="text-xl font-bold mb-4 font-plus-jakarta text-gray-800">Documents</h2>
			<Card className="border border-gray-200">
				<CardContent className="p-6">
					{/* Upload Section */}
					<div className="mb-6">
						<h3 className="text-base font-semibold font-inter text-gray-900 mb-4">Upload New Document</h3>
						<div className="space-y-3">
							<div>
								<Label htmlFor="document-type" className="text-sm font-medium text-gray-700 mb-1.5 block">
									Document Type
								</Label>
								<Select>
									<SelectTrigger id="document-type" className="h-[36px]">
										<SelectValue placeholder="Select document type" />
									</SelectTrigger>
									<SelectContent>
										{documentTypes.map((type) => (
											<SelectItem key={type} value={type.toLowerCase().replace(" ", "-")}>
												{type}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							<div className="border-2 border-dashed rounded-lg p-6 transition-colors hover:border-primary-500/50 hover:bg-gray-50">
								<div className="flex flex-col items-center justify-center space-y-3 text-center">
									<div className="p-2 bg-primary-50 rounded-full">
										<Upload className="w-5 h-5 text-primary-600" />
									</div>
									<div>
										<p className="text-gray-600 text-sm">Drag and drop your file here, or</p>
										<Button variant="link" className="text-primary-600 text-sm h-auto p-0">
											click to browse
										</Button>
									</div>
									<p className="text-xs text-gray-500">Supported formats: PDF, DOC, DOCX (Max size: 5MB)</p>
								</div>
							</div>
						</div>
					</div>

					{/* Divider */}
					<div className="border-t border-gray-200 mb-6" />

					{/* Documents List */}
					<div>
						<div className="mb-4">
							<h3 className="text-base font-semibold font-inter text-gray-900">My Documents</h3>
						</div>

						<div className="space-y-2">
							{documents.map((doc) => (
								<div
									key={doc.id}
									className="flex items-center justify-between p-3.5 bg-gray-50 rounded-lg transition-colors hover:bg-gray-100"
								>
									<div className="flex items-center gap-3">
										<div className="p-2 bg-primary-50 rounded-md">
											<FileIcon className="w-4 h-4 text-primary-700" />
										</div>
										<div>
											<h4 className="text-[14px] font-medium text-gray-900 font-inter mb-0.5">{doc.name}</h4>
											<div className="flex items-center gap-3">
												<span className="text-xs text-gray-600">Uploaded on {doc.uploadDate}</span>
												<span className="text-xs text-gray-600">{doc.size}</span>
											</div>
										</div>
									</div>

									<div className="flex items-center gap-6">
										<Badge variant="secondary" className="text-xs font-medium bg-gray-100 text-gray-700">
											{doc.type}
										</Badge>
										<div className="flex items-center gap-2">
											<Button variant="ghost" size="sm" title="Download" className="h-8 w-8 p-0 hover:bg-gray-100">
												<DownloadIcon className="w-4 h-4 text-gray-600" />
												<span className="sr-only">Download</span>
											</Button>
											<Button
												variant="ghost"
												size="sm"
												onClick={() => deleteDocument(doc.id)}
												title="Delete"
												className="h-8 w-8 p-0 hover:bg-red-50 hover:text-red-600"
											>
												<Trash className="w-4 h-4" />
												<span className="sr-only">Delete</span>
											</Button>
										</div>
									</div>
								</div>
							))}

							{documents.length === 0 && (
								<Alert>
									<HelpCircle className="h-4 w-4" />
									<AlertTitle>No documents</AlertTitle>
									<AlertDescription>
										You haven&apos;t uploaded any documents yet. Use the form above to add your first document.
									</AlertDescription>
								</Alert>
							)}
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}
