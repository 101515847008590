import React from "react";

import { SearchIcon, Sort, XIcon } from "../../../assets/icons/interface/interface";
import { cn } from "../../../handlers/cn.utils";
import { useJobCategories } from "../../../services/create-resume/queries";
import { Button } from "./Button";
import { Input } from "./Input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";
import { Slider } from "./Slider";

function Search({
	searchedAddress,
	selectedCategory,
	searchedKeyWord,
	setSearchedKeyWord,
	setSelectedCategory,
	fetchJobsOnSearch,
	jobTypes,
	isUserLoggedIn,
	experienceLevels,
	addressLocation,
	setAddressLocation,
	selectedJobTypes,
	setSelectedJobTypes,
	selectedExperienceLevels,
	setSelectedExperienceLevels,
}) {
	const [renderingCount, setRenderingCount] = React.useState(0);
	const [categories, setCategories] = React.useState([]);
	const [showFilters, setShowFilters] = React.useState(false);
	const [searchTerm, setSearchTerm] = React.useState("");
	const filterContentRef = React.useRef(null);
	const [salaryRange, setSalaryRange] = React.useState([0, 150000]);

	// Update hasActiveFilters function
	const hasActiveFilters = () => {
		return (
			searchTerm !== "" ||
			addressLocation !== "" ||
			salaryRange[0] !== 0 ||
			salaryRange[1] !== 150000 ||
			selectedJobTypes.length > 0 ||
			selectedExperienceLevels.length > 0
		);
	};

	// Update clearAllFilters function
	const clearAllFilters = () => {
		setSearchTerm("");
		setAddressLocation("");
		setSalaryRange([0, 150000]);
		setSelectedJobTypes([]);
		setSelectedExperienceLevels([]);
		setShowFilters(false);

		if (jobTypes.length === 0) {
			setSearchedKeyWord("");
			fetchJobsOnSearch({ page: 1, keyWord: "", location: "" });
		}
	};

	const { data: response, isLoading: loadingCategories } = useJobCategories();

	React.useEffect(() => {
		if (response && !loadingCategories) {
			setCategories(response.data);
		}
	}, [response, loadingCategories]);

	React.useEffect(() => {
		if (searchedKeyWord !== undefined || searchedAddress !== undefined) {
			if (renderingCount === 0) fetchJobsOnSearch({ page: 1 });

			if (renderingCount > 0 && selectedCategory === "all") {
				const timer = setTimeout(() => {
					fetchJobsOnSearch({ page: 1 });
				}, 1000);

				return () => clearTimeout(timer);
			}
		}
		setRenderingCount(renderingCount + 1);
	}, [searchedKeyWord]);

	return (
		<>
			<div className={`w-full bg-white border-b ${isUserLoggedIn ? "border-t" : ""} border-gray-200`}>
				<div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 py-4">
					<div className="flex flex-col space-y-2">
						<div className="flex flex-col sm:flex-row gap-2">
							{/* Category Filter */}
							<div className="relative w-full sm:w-[180px]">
								<Select
									value={selectedCategory}
									onValueChange={(value) => {
										setSelectedCategory(value);
										clearAllFilters();
										setShowFilters(false);
										fetchJobsOnSearch({ page: 1, categoryName: value });
									}}
								>
									<SelectTrigger className="w-full border-gray-300 bg-white text-gray-800">
										<SelectValue placeholder="Select category" />
									</SelectTrigger>
									<SelectContent>
										{[
											{ label: "All", value: "all" },
											...categories.map((category) => {
												return { label: category._id, value: category._id };
											}),
										].map((cat) => (
											<SelectItem key={cat.value} value={cat.value}>
												{cat.label}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							{/* Keyword Search */}
							<div className="relative flex-grow">
								<div className="relative">
									<SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
									<Input
										placeholder="Search jobs, companies, or keywords..."
										className="px-9 h-10 bg-gray-50 border-0 text-gray-900 placeholder:text-gray-600 ignore-focus-behavior"
										value={searchedKeyWord}
										onChange={(e) => setSearchedKeyWord(e.target.value)}
									/>
								</div>
							</div>
							{/* Filters and Clear Buttons */}
							<div className="flex items-center gap-2">
								<Button
									disabled={jobTypes.length === 0}
									variant="outline"
									className="w-full sm:w-auto flex items-center justify-center gap-2 text-gray-700"
									onClick={() => setShowFilters(!showFilters)}
								>
									<Sort className="w-4 h-4" />
									<span>Filters</span>
								</Button>

								{hasActiveFilters() && (
									<Button
										variant="ghost"
										size="sm"
										onClick={clearAllFilters}
										className="text-primary-600 hover:text-primary-700 hover:bg-primary-50"
									>
										<XIcon className="w-4 h-4 mr-1" />
										Clear
									</Button>
								)}
							</div>
						</div>
						{/* Collapsible Filters */}
						<div
							className={cn(
								"overflow-hidden transition-[max-height] duration-300 ease-in-out",
								showFilters ? "max-h-[500px]" : "max-h-0",
							)}
						>
							<div ref={filterContentRef} className="mt-4 pt-4 border-t border-gray-200">
								<div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
									{/* Job Type Filter */}
									<div>
										<div className="flex items-center justify-between mb-2">
											<h3 className="text-sm font-medium text-gray-950 font-inter">Job Type</h3>
										</div>
										<div className="space-y-1">
											{jobTypes.map((type) => (
												<label
													key={type.name}
													className="flex items-center justify-between py-1 cursor-pointer group hover:bg-gray-50 rounded-md px-2 -mx-2"
												>
													<div className="flex items-center">
														<input
															type="checkbox"
															checked={selectedJobTypes.includes(type.name)}
															onChange={(e) => {
																if (e.target.checked) {
																	setSelectedJobTypes([...selectedJobTypes, type.name]);
																} else {
																	setSelectedJobTypes(selectedJobTypes.filter((t) => t !== type.name));
																}
															}}
															className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
														/>
														<span className="ml-2 text-sm text-gray-600 group-hover:text-gray-900">{type.name}</span>
													</div>
													<span className="text-xs text-gray-500 tabular-nums">{type.count}</span>
												</label>
											))}
										</div>
									</div>

									{/* Experience Level Filter */}
									<div>
										<div className="flex items-center justify-between mb-2">
											<h3 className="text-sm font-medium text-gray-950 font-inter">Experience Level</h3>
										</div>
										<div className="space-y-1">
											{experienceLevels.map((level) => (
												<label
													key={level.name}
													className="flex items-center justify-between py-1 cursor-pointer group hover:bg-gray-50 rounded-md px-2 -mx-2"
												>
													<div className="flex items-center">
														<input
															type="checkbox"
															checked={selectedExperienceLevels.includes(level.name)}
															onChange={(e) => {
																if (e.target.checked) {
																	setSelectedExperienceLevels([...selectedExperienceLevels, level.name]);
																} else {
																	setSelectedExperienceLevels(selectedExperienceLevels.filter((l) => l !== level.name));
																}
															}}
															className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
														/>
														<span className="ml-2 text-sm text-gray-600 group-hover:text-gray-900">{level.name}</span>
													</div>
													<span className="text-xs text-gray-500 tabular-nums">{level.count}</span>
												</label>
											))}
										</div>
									</div>

									{/* Salary Range Filter with Slider */}
									<div>
										<div className="flex items-center justify-between mb-4">
											<h3 className="text-sm font-medium text-gray-950 font-inter">Salary Range</h3>
											<span className="text-sm text-gray-700">
												${(salaryRange[0] / 1000).toFixed(0)}k - ${(salaryRange[1] / 1000).toFixed(0)}k
											</span>
										</div>
										<div className="px-2 space-y-5">
											<Slider
												defaultValue={[0, 150]}
												max={150}
												step={10}
												value={[salaryRange[0] / 1000, salaryRange[1] / 1000]}
												onValueChange={(value) => setSalaryRange([value[0] * 1000, value[1] * 1000])}
												className="w-full"
											/>
											<div className="flex justify-between items-center">
												<div className="space-y-1">
													<div className="text-sm text-gray-700">Min</div>
													<div className="text-sm font-medium text-gray-900">
														${(salaryRange[0] / 1000).toFixed(0)}k
													</div>
												</div>
												<div className="space-y-1 text-right">
													<div className="text-sm text-gray-700">Max</div>
													<div className="text-sm font-medium text-gray-900">
														${(salaryRange[1] / 1000).toFixed(0)}k
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Search;
