import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const CATEGORIES_WITH_ICONS = [
	"Consulting And Strategy",
	"Finance",

	"Customer Service",
	"Healthcare and Medical",

	"Education",
	"Information Technology",

	"Engineering",
	"Marketing and Communications",
];

function Categories({ categories }) {
	const navigate = useNavigate();
	const [filteredCategories, setFilteredCategories] = React.useState(categories);
	useEffect(() => {
		const categoryData = categories.filter((category) => CATEGORIES_WITH_ICONS.includes(category._id));
		if (categoryData.length < 8) {
			const remainingCategories = categories.filter((category) => !CATEGORIES_WITH_ICONS.includes(category._id));
			const randomCategories = remainingCategories.sort(() => 0.5 - Math.random()).slice(0, 8 - categoryData.length);
			setFilteredCategories([...categoryData, ...randomCategories]);
		} else {
			setFilteredCategories(categoryData);
		}
	}, [categories]);

	return (
		<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
			{filteredCategories.map((category, index) => (
				<div
					className="flex items-center p-4 h-full cursor-pointer bg-white rounded-lg shadow-sm transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-md border border-gray-100"
					key={index}
					onClick={() => {
						navigate("/jobs", {
							state: {
								jobCategory: category._id,
							},
						});
					}}
				>
					<div className="bg-primary-100 flex items-center justify-center p-3 rounded-full mr-4 transition-all duration-300 ease-in-out group-hover:bg-primary-200">
						<img
							alt={category.category}
							loading="lazy"
							width="32"
							height="32"
							decoding="async"
							data-nimg="1"
							src={`/images/landing/${category._id}.svg`}
						/>
					</div>
					<div className="text-left">
						<h3 className="text-base font-medium text-gray-800 font-inter transition-colors duration-300 ease-in-out group-hover:text-primary-600">
							{category._id}
						</h3>
						<span className="text-sm text-gray-500 transition-colors duration-300 ease-in-out group-hover:text-primary-600">
							{category.count} jobs
						</span>
					</div>
				</div>
			))}
		</div>
	);
}

export default Categories;
