import * as LabelPrimitive from "@radix-ui/react-label";
import { cva } from "class-variance-authority";
import React from "react";

import { cn } from "../../handlers/cn.utils";

const labelVariants = cva("text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70");

const Label = React.forwardRef((props, ref) => {
	const { className, ...restProps } = props;

	return <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...restProps} />;
});

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
