import React, { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

import { months } from "../../../handlers/utilityFunctions";
import { isValidEmail, isValidPhoneNumber } from "../../../handlers/validationFunctions";
import { getJobFunctions } from "../../../seed/getMetadata";
import { regions } from "../../../seed/job-preferences-data";
import Dropdown from "../components/Dropdown";
import OptionBadge from "../components/OptionBadge";
import AuthLayout from "../layout/AuthLayout";

function ProfileInfo() {
	const navigate = useNavigate();
	const profileData = useMemo(() => JSON.parse(localStorage.getItem("savedPreferences")), []);

	const [jobTitle, setJobTitle] = React.useState("");
	const [areaOfExpertise, setAreaOfExpertise] = React.useState([]);
	const [jobType, setJobType] = React.useState("Full-time");

	const [currentLocation, setCurrentLocation] = React.useState("");
	const [relocate, setRelocate] = React.useState("");

	const [recentGraduate, setRecentGraduate] = React.useState(false);
	const [graduationMonth, setGraduationMonth] = React.useState("");
	const [graduationYear, setGraduationYear] = React.useState("");

	const [startMonth, setStartMonth] = React.useState("");
	const [startYear, setStartYear] = React.useState("");

	const [personalInfo, setPersonalInfo] = React.useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
	});

	useEffect(() => {
		if (!profileData) return;
		setJobTitle(profileData.jobTitle);
		setAreaOfExpertise(profileData.areaOfExpertise);
		setJobType(profileData.jobType);
		setCurrentLocation(profileData.currentLocation);
		setRelocate(profileData.relocate);
		setRecentGraduate(profileData.recentGraduate);
		setGraduationMonth(profileData.graduationMonth);
		setGraduationYear(profileData.graduationYear);
		setStartMonth(profileData.startMonth);
		setStartYear(profileData.startYear);
		setPersonalInfo(profileData.personalInfo);
	}, [profileData]);

	const onSubmit = (e) => {
		e.preventDefault();

		// validation
		if (!jobTitle) {
			toast.error("Please enter your desired job title");
			return;
		}

		if (areaOfExpertise.length === 0) {
			toast.error("Please select your area of expertise");
			return;
		}

		if (!currentLocation) {
			toast.error("Please select your current location");
			return;
		}

		if (!relocate) {
			toast.error("Please select if you are open to relocating");
			return;
		}

		if (!recentGraduate) {
			toast.error("Please select if you are a recent graduate");
			return;
		}

		if (recentGraduate === "Yes" && (!graduationMonth || !graduationYear)) {
			toast.error("Please select when you graduated or expect to graduate");
			return;
		}

		if (!startMonth || !startYear) {
			toast.error("Please select when you started your professional career");
			return;
		}

		if (!personalInfo.firstName) {
			toast.error("Please enter your first name");
			return;
		}

		if (!personalInfo.lastName) {
			toast.error("Please enter your last name");
			return;
		}

		if (!personalInfo.email) {
			toast.error("Please enter your email address");
			return;
		}

		if (!isValidEmail(personalInfo.email)) {
			toast.error("Please enter a valid email address");
			return;
		}

		if (personalInfo.phone && !isValidPhoneNumber(personalInfo.phone)) {
			toast.error("Please enter a valid phone number, eg. +2557xxxxxxxx");
			return;
		}

		const data = {
			jobTitle,
			areaOfExpertise,
			jobType,
			currentLocation,
			relocate,
			recentGraduate,
			graduationMonth,
			graduationYear,
			startMonth,
			startYear,
			personalInfo,
		};

		localStorage.setItem("savedPreferences", JSON.stringify(data));

		navigate("/register/create-account");
	};

	return (
		<AuthLayout>
			<div className="py-12">
				<Link to="/" className="block md:hidden">
					<img
						src="/images/logo.png"
						alt="DProz Logo"
						className="p-2 mb-8 mx-auto"
						style={{
							width: "180px",
						}}
					/>
				</Link>
				<div className="mb-6">
					<p className="text-base text-right">
						Already have an account?{" "}
						<Link to="/signin" className="text-primary-700 font-bold">
							Login
						</Link>
					</p>
				</div>
				<div className="mb-6">
					<h3 className="!font-bold text-[28px] leading-tight text-[#161616] !font-plus-jakarta mb-2">
						Your dream job is just a click away!
					</h3>
					<p className="text-[#5C5C5C] text-sm">Share a bit about yourself, and we’ll find your perfect match!</p>
				</div>
				<form onSubmit={onSubmit} className="">
					<div className="flex flex-col gap-1 mb-4">
						<label className="font-semibold text-sm text-gray-700" htmlFor="">
							What’s your dream job title?
						</label>
						<input
							type="text"
							className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
							placeholder="Enter your desired job title"
							value={jobTitle}
							onChange={(e) => setJobTitle(e.target.value)}
						/>
					</div>

					<div className="flex flex-col gap-1 mb-3">
						<label className="font-semibold text-sm text-gray-700" htmlFor="">
							What’s your superpower skill or area of expertise?
						</label>
						<Dropdown
							placeholder="Select all that apply"
							multiple
							options={[...getJobFunctions()].sort()}
							onChange={(val) => {
								if (areaOfExpertise.includes(val)) {
									setAreaOfExpertise(areaOfExpertise.filter((item) => item !== val));
								} else {
									setAreaOfExpertise([...areaOfExpertise, val]);
								}
							}}
							value={areaOfExpertise}
						/>
					</div>

					<div className="flex flex-col gap-1 mb-3">
						<label className="font-semibold text-sm text-gray-700" htmlFor="">
							What type of roles are you looking for?
						</label>
						<div className="flex gap-3 mt-1 flex-wrap">
							{["Full-Time", "Part-Time", "Contractor", "Temporary", "Internship", "Other"].map((item, index) => (
								<OptionBadge onSelect={() => setJobType(item)} value={item} selected={jobType === item} key={index} />
							))}
						</div>
					</div>

					<div className="flex flex-col gap-1 mb-3">
						<label className="font-semibold text-sm text-gray-700" htmlFor="">
							Where are you currently located?
						</label>
						<Dropdown
							placeholder="Select your location"
							options={regions}
							onChange={(val) => {
								setCurrentLocation(val);
							}}
							value={currentLocation}
							search
						/>
					</div>

					<div className="flex flex-col gap-1 mb-3">
						<label className="font-semibold text-sm text-gray-700" htmlFor="">
							Are you open to relocating for work?
						</label>
						<Dropdown
							placeholder="Select an option"
							options={["Yes", "No"]}
							onChange={(val) => {
								setRelocate(val);
							}}
							value={relocate}
						/>
					</div>
					<div className="flex flex-col gap-1 mb-3">
						<label className="font-semibold text-sm text-gray-700" htmlFor="">
							⁠Are you a recent graduate?
						</label>
						<div className="flex gap-3 mt-1 flex-wrap">
							{["Yes", "No"].map((item, index) => (
								<OptionBadge
									onSelect={() => setRecentGraduate(item)}
									value={item}
									selected={recentGraduate === item}
									key={index}
								/>
							))}
						</div>
						{recentGraduate === "Yes" && (
							<>
								<label className="font-semibold text-sm text-gray-700 mt-2" htmlFor="">
									When did you graduate or expect to graduate?
								</label>
								<div className="grid grid-cols-2 gap-3">
									<Dropdown
										placeholder="Select Month"
										options={months}
										onChange={(val) => {
											setGraduationMonth(val);
										}}
										value={graduationMonth}
									/>
									<Dropdown
										placeholder="Select Year"
										options={(function () {
											const years = [];
											for (let i = new Date().getFullYear() - 3; i <= new Date().getFullYear() + 2; i++) {
												years.push(i.toString());
											}
											return years.reverse();
										})()}
										onChange={(val) => {
											setGraduationYear(val);
										}}
										value={graduationYear}
									/>
								</div>
							</>
						)}
					</div>

					<div className="flex flex-col gap-1 mb-3">
						<label className="font-semibold text-sm text-gray-700" htmlFor="">
							When did you start your professional career?
						</label>

						<div className="grid grid-cols-2 gap-3">
							<Dropdown
								placeholder="Select Month"
								options={months}
								onChange={(val) => {
									setStartMonth(val);
								}}
								value={startMonth}
							/>
							<Dropdown
								placeholder="Select Year"
								options={(function () {
									const years = [];
									for (let i = 1980; i <= new Date().getFullYear(); i++) {
										years.push(i.toString());
									}
									return years.reverse();
								})()}
								onChange={(val) => {
									setStartYear(val);
								}}
								value={startYear}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-1 mb-3">
						<label className="font-semibold text-sm text-gray-700" htmlFor="">
							Tell us a bit about yourself
						</label>
						<div className="grid grid-cols-2 gap-3">
							<input
								type="text"
								className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
								placeholder="Your first name"
								value={personalInfo.firstName}
								name="firstName"
								onChange={(e) => setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value })}
							/>
							<input
								type="text"
								className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
								placeholder="Your last name"
								value={personalInfo.lastName}
								name="lastName"
								onChange={(e) => setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value })}
							/>

							<input
								type="email"
								className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
								placeholder="Email Address"
								value={personalInfo.email}
								name="email"
								onChange={(e) => setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value })}
							/>
							<input
								type="tel"
								className="border border-gray-200 h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]"
								placeholder="Phone number (optional)"
								value={personalInfo.phone}
								name="phone"
								onChange={(e) => setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value })}
							/>
						</div>
					</div>
					<button
						className="w-full rounded-md bg-primary-700 text-white font-semibold font-inter text-base py-2 flex items-center justify-center"
						type="submit"
						onClick={onSubmit}
					>
						Continue
					</button>
				</form>
			</div>
		</AuthLayout>
	);
}

export default ProfileInfo;
