import React from "react";

function SVGPattern({ className = "", style }) {
	return (
		<svg className={className} style={style} viewBox="0 0 500 1200" xmlns="http://www.w3.org/2000/svg">
			<mask id="b" x="0" y="0" width="500" height="1200">
				<path fill="url(#a)" d="M0 0h1000v1000H0z" />
			</mask>
			<g style={{ transformOrigin: "center center" }} stroke="#dbdbe2" strokeWidth="2" mask="url(#b)">
				<path fill="none" d="M0 0h200v200H0zM200 0h200v200H200zM400 0h200v200H400zM600 0h200v200H600z" />
				<path fill="#dbdbe2fd" d="M800 0h200v200H800z" />
				<path fill="none" d="M1000 0h200v200h-200zM1200 0h200v200h-200zM1400 0h200v200h-200z" />
				<path fill="#dbdbe2fe" d="M1600 0h200v200h-200z" />
				<path
					fill="none"
					d="M1800 0h200v200h-200zM0 200h200v200H0zM200 200h200v200H200zM400 200h200v200H400zM600 200h200v200H600zM800 200h200v200H800zM1000 200h200v200h-200zM1200 200h200v200h-200zM1400 200h200v200h-200z"
				/>
				<path fill="#dbdbe2bb" d="M1600 200h200v200h-200z" />
				<path fill="none" d="M1800 200h200v200h-200zM0 400h200v200H0zM200 400h200v200H200zM400 400h200v200H400z" />
				<path fill="#dbdbe250" d="M600 400h200v200H600z" />
				<path
					fill="none"
					d="M800 400h200v200H800zM1000 400h200v200h-200zM1200 400h200v200h-200zM1400 400h200v200h-200zM1600 400h200v200h-200zM1800 400h200v200h-200z"
				/>
				<path fill="#dbdbe2ec" d="M0 600h200v200H0z" />
				<path
					fill="none"
					d="M200 600h200v200H200zM400 600h200v200H400zM600 600h200v200H600zM800 600h200v200H800zM1000 600h200v200h-200zM1200 600h200v200h-200zM1400 600h200v200h-200zM1600 600h200v200h-200zM1800 600h200v200h-200zM0 800h200v200H0zM200 800h200v200H200zM400 800h200v200H400zM600 800h200v200H600zM800 800h200v200H800z"
				/>
				<path fill="#dbdbe2eb" d="M1000 800h200v200h-200z" />
				<path fill="none" d="M1200 800h200v200h-200zM1400 800h200v200h-200zM1600 800h200v200h-200z" />
				<path fill="#dbdbe28e" d="M1800 800h200v200h-200z" />
				<path fill="#dbdbe2d6" d="M0 1000h200v200H0z" />
				<path
					fill="none"
					d="M200 1000h200v200H200zM400 1000h200v200H400zM600 1000h200v200H600zM800 1000h200v200H800zM1000 1000h200v200h-200zM1200 1000h200v200h-200zM1400 1000h200v200h-200zM1600 1000h200v200h-200zM1800 1000h200v200h-200zM0 1200h200v200H0z"
				/>
				<path fill="#dbdbe2c9" d="M200 1200h200v200H200z" />
				<path fill="#dbdbe23b" d="M400 1200h200v200H400z" />
				<path fill="none" d="M600 1200h200v200H600zM800 1200h200v200H800z" />
				<path fill="#dbdbe2c4" d="M1000 1200h200v200h-200z" />
				<path fill="none" d="M1200 1200h200v200h-200zM1400 1200h200v200h-200z" />
				<path fill="#dbdbe293" d="M1600 1200h200v200h-200z" />
				<path fill="none" d="M1800 1200h200v200h-200z" />
			</g>
			<defs>
				<radialGradient id="a">
					<stop offset="50%" stopColor="#fff" stopOpacity="0" />
					<stop offset="1" stopColor="#fff" stopOpacity=".5" />
				</radialGradient>
			</defs>
		</svg>
	);
}

export default SVGPattern;
