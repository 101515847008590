import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import AuthLayout from "../layout/AuthLayout";
import JobSeekerRegistrationForm from "./JobSeekerRegistrationForm";

function RegisterScreen() {
	const navigate = useNavigate();
	if (!localStorage.getItem("savedPreferences")) {
		navigate("/register");
	}

	const profileData = useMemo(() => JSON.parse(localStorage.getItem("savedPreferences")), []);

	if (!profileData) {
		window.location.href = "/register";
	}

	return (
		<AuthLayout>
			<div className="py-12">
				<h3 className="font-plus-jakarta font-bold text-2xl">Last Step: Now, let’s create your account!</h3>
				<div className="my-7 border border-gray-200 rounded p-4">
					<div className="flex justify-between items-center">
						<h5 className="text-md">Your Preferences Summary</h5>

						<button
							className="text-[#0F172A] aspect-square border border-gray-200 rounded px-2 py-1"
							onClick={() => navigate("/register")}
						>
							<i className="fa-regular fa-pen-to-square" />
						</button>
					</div>

					<ul>
						<li className="text-sm mb-2">
							<span className="font-bold">Dream Job Title:</span> {profileData.jobTitle}
						</li>
						<li className="text-sm mb-2">
							<span className="font-bold">Superpower Skill/Expertise:</span> {profileData.areaOfExpertise.join(", ")}
						</li>
						<li className="text-sm mb-2">
							<span className="font-bold">Role Type Preference:</span> {profileData.jobType}
						</li>
						<li className="text-sm mb-2">
							<span className="font-bold">Current Location:</span> {profileData.currentLocation}
						</li>
						<li className="text-sm mb-2">
							<span className="font-bold">Willing to relocate:</span> {profileData.relocate}
						</li>
						<li className="text-sm mb-2">
							<span className="font-bold">Recent Graduate:</span> {profileData.recentGraduate}
							{profileData.recentGraduate === "Yes" &&
								` (${profileData.graduationMonth} ${profileData.graduationYear})`}
						</li>
						<li className="text-sm mb-2">
							<span className="font-bold">Working since:</span> {profileData.startMonth} {profileData.startYear}
						</li>

						<li className="text-sm mb-2">
							<span className="font-bold">Full Name:</span> {profileData.personalInfo.firstName}{" "}
							{profileData.personalInfo.lastName}
						</li>
						<li className="text-sm mb-2">
							<span className="font-bold">Email Address:</span> {profileData.personalInfo.email}
						</li>
					</ul>
				</div>
				<JobSeekerRegistrationForm personalInfo={profileData.personalInfo} />
			</div>
		</AuthLayout>
	);
}

export default RegisterScreen;
