import "../css/global.css";
import "../css/home.css";
import "../css/responsive.css";

import React from "react";

import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import Header from "../../Profile/components/Header";
import Footer from "../Footer";

function AboutUs() {
	const isLoggedIn = useIsLoggedIn();

	return (
		<>
			<Header isLoggedIn={isLoggedIn} />
			<section className="custom-banner mt-0">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-12">
							<div className="">
								<h2 className="text-white">Empowering Job Seekers and HR Professionals In Tanzania</h2>
								<p className="text-white text-larger">
									At Dproz, our mission is to transform the world of recruitment and employment. We understand the
									challenges and complexities faced by job seekers and HR professionals in today’s dynamic job market.
									That’s why we’re committed to providing innovative solutions that empower both groups.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="fancy-border-radius" />

			<section className="home-common-section py-5">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-12  order-2 order-md-1">
							<div className="">
								<h3 className="mb-4">Our Story</h3>
								<p className="mb-4 text-larger">
									With Dproz, you’re in control of your success story. We enable you to reclaim your time, make a
									memorable impact, and accelerate your career. This is achieved through personalized resume creation,
									insightful job fit feedback, and precise job connections – all tailored for your journey to success.
								</p>
								<h3 className="mb-4 ">What Drives Us</h3>
								<p className="mb-4 text-larger">
									We’re passionate about empowering job seekers to excel in their careers and helping HR professionals
									redefine how they work. We believe in equal opportunities and aim to bridge the gap between potential
									and success for job seekers, while also simplifying and enhancing the hiring process for HR teams.
								</p>
								<h3 className="mb-4">Our Story</h3>
								<p className="mb-4 text-larger">
									Dproz offers a comprehensive platform that caters to both job seekers and HR professionals. We provide
									innovative tools for crafting professional resumes, job search support, and a powerful automated
									screening system for HR professionals. Our goal is to make the job market accessible and efficient for
									everyone involved.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="home-evaluation-process p-5 bg-light">
				<div className="container">
					<h3 className="mb-5">Why Choose Dproz</h3>
					<div className="row mb-5">
						<div className="col-md-3 mb-5 mb-md-0">
							<div className="evaluation-box bg-white h-100 ">
								<h3 className="mb-3">Efficiency</h3>
								<p className="text-larger text-medium">
									Dproz streamlines the hiring process, reducing the time and effort spent on manual tasks.
								</p>
							</div>
						</div>
						<div className="col-md-3 mb-5 mb-md-0">
							<div className="evaluation-box bg-white h-100 ">
								<h3 className="mb-3">Quality</h3>
								<p className="text-larger text-medium">
									Our system ensures that top talent is never overlooked, benefiting both job seekers and HR
									professionals.
								</p>
							</div>
						</div>
						<div className="col-md-3 mb-5 mb-md-0">
							<div className="evaluation-box bg-white h-100 ">
								<h3 className="mb-3">Support</h3>
								<p className="text-larger text-medium">
									We’re here to assist you every step of the way, whether you’re a job seeker or an HR professional.
								</p>
							</div>
						</div>
						<div className="col-md-3 mb-5 mb-md-0">
							<div className="evaluation-box bg-white h-100 ">
								<h3 className="mb-3">Guarantee</h3>
								<p className="text-larger text-medium">
									Your satisfaction is our priority. If Dproz does’t meet your expectations, we’re ready to work with
									you on a solution.
								</p>
							</div>
						</div>
					</div>

					<p style={{ fontSize: "20px" }}>Join Us in Shaping the Future</p>
					<p className="text-larger">
						Dproz is more than a job search platform; it’s a community of individuals who share the vision of a brighter
						future. We invite both job seekers and HR professionals to join us in this journey of empowerment, where we
						eliminate self-doubt, simplify the hiring process, and contribute to your success.
					</p>
					<div className="d-flex justify-content-center">
						<a
							href="https://tz.linkedin.com/company/dproz-hiring"
							target="_blank"
							rel="noreferrer"
							className="btn home-btn home-btn-primary px-5 mt-3"
						>
							Follow Us On Linked In
						</a>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default AboutUs;
