import React from "react";

import { cn } from "../../../handlers/cn.utils";

function OptionBadge({ onSelect, value, selected }) {
	const inputRef = React.useRef();

	const onClick = () => {
		inputRef.current.click();
	};

	return (
		<div>
			<input
				type="checkbox"
				className="hidden"
				id={value}
				name={value}
				checked={selected}
				onChange={onSelect}
				ref={inputRef}
			/>
			<div
				className={cn(
					"px-4 py-2 rounded-full border text-[14px] font-inter hover:bg-gray-50 text-gray-700 cursor-pointer",
					{
						"border-primary-600 bg-primary-50": selected,
					},
				)}
				onClick={onClick}
			>
				<label
					htmlFor={value}
					className={cn("text-sm", {
						"text-gray-700": !selected,
						"text-primary-700": selected,
					})}
				>
					{value}
				</label>
			</div>
		</div>
	);
}

export default OptionBadge;
