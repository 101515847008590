import React, { useState } from "react";

import Drawer from "../../../components/Drawer/Drawer";
import JobListingItem from "../../JobsPage/components/JobListingItem";
import ViewJob from "../../StaticPages/HomePage/components/ViewJob";

function CompanyJobs({ jobs, logo }) {
	const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	return (
		<>
			<Drawer
				isOpen={showJobDetailsModal}
				onClose={() => {
					setShowJobDetailsModal(false);
				}}
			>
				<ViewJob data={selectedJob} setData={setSelectedJob} setShowJobDetailsModal={setShowJobDetailsModal} />
			</Drawer>
			<div className="mt-4">
				<div className="pt-6">
					<h2 className="text-2xl font-semibold mb-4 font-plus-jakarta">
						{jobs.length === 0 ? "No Open Positions Available" : "Open Positions"}
					</h2>
					<div className="">
						{jobs.map((job, index) => (
							<JobListingItem
								key={index}
								job={{
									...job,
									logo: logo,
								}}
								showCompany={false}
								onClick={() => {
									setSelectedJob(job);
									setShowJobDetailsModal(true);
								}}
								onApply={() => {
									setSelectedJob(job);
									setShowJobDetailsModal(true);
								}}
								titleClassName="text-sm font-semibold"
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
}

export default CompanyJobs;
