import { cva } from "class-variance-authority";
import React, { forwardRef } from "react";

import { AlertIcon } from "../../assets/icons/interface/interface";
import { cn } from "../../handlers/cn.utils";

const alertVariants = cva(
	"relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
	{
		variants: {
			variant: {
				default: "bg-primary-100 text-primary-800 border-primary-200",
				destructive: "bg-red-100 text-red-800 border-red-200",
				warning: "bg-yellow-100 text-yellow-800 border-yellow-200",
				info: "bg-blue-100 text-blue-800 border-blue-200",
				success: "bg-green-100 text-green-800 border-green-200",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

const Alert = forwardRef(({ className, variant, ...props }, ref) => (
	<div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props}>
		<AlertIcon />
		{props.children}
	</div>
));
Alert.displayName = "Alert";

const AlertTitle = forwardRef(({ className, ...props }, ref) => (
	<h5 ref={ref} className={cn("mb-1 font-medium leading-none tracking-tight", className)} {...props} />
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = forwardRef(({ className, ...props }, ref) => (
	<div ref={ref} className={cn("text-sm [&_p]:leading-relaxed", className)} {...props} />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription, AlertTitle };
