import { motion } from "framer-motion";
import React, { useEffect } from "react";

import { CheckIcon, Clock, HelpCircle, XCircle } from "../../../assets/icons/interface/interface";
import { Button } from "../../JobsPage/components/Button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./DropdownMenu";

const statusOptions = [
	{
		value: "Actively Looking",
		label: "Open to opportunities",
		icon: <CheckIcon className="h-4 w-4 text-green-500" />,
		description: "Interested in new roles. Profile fully visible to employers.",
	},
	{
		value: "Open for Reach outs",
		label: "Not looking",
		icon: <Clock className="h-4 w-4 text-yellow-500" />,
		description: "Not actively seeking, but open to contact. Limited visibility.",
	},
	{
		value: "Not Looking",
		label: "Unavailable",
		icon: <XCircle className="h-4 w-4 text-red-500" />,
		description: "Not open to new opportunities. Profile hidden from employers.",
	},
];

const defaultOption = {
	value: "unknown",
	label: "Set job status",
	icon: <HelpCircle className="h-4 w-4 text-gray-500" />,
	description: "Select your current job seeking status.",
};

const JobStatusBadge = ({ initialStatus = "unknown", onStatusChange }) => {
	const [currentStatus, setCurrentStatus] = React.useState("");

	const currentStatusOption = statusOptions.find((option) => option.value === currentStatus) || defaultOption;

	const handleStatusChange = (value) => {
		setCurrentStatus(value);
		if (typeof onStatusChange === "function") {
			onStatusChange(value);
		}
	};

	useEffect(() => {
		setCurrentStatus(initialStatus);
	}, [initialStatus]);

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					className="flex items-center space-x-2 px-4 py-2 rounded-full border-2 hover:bg-gray-100 transition-colors duration-200"
				>
					<span className="font-medium text-sm">{currentStatusOption.label}</span>
					<span className="animate-pulse">{currentStatusOption.icon}</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent asChild>
				<motion.div
					initial={{ opacity: 0, y: -10 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: -10 }}
					transition={{ duration: 0.2 }}
					className="w-72 p-2 rounded-lg shadow-lg bg-white"
				>
					{statusOptions.map((option) => (
						<DropdownMenuItem
							key={option.value}
							onSelect={() => handleStatusChange(option.value)}
							className="flex flex-col items-start py-3 px-4 hover:bg-gray-100 rounded-md transition-colors duration-200"
						>
							<div className="flex items-center font-medium text-sm">
								{option.icon}
								<span className="ml-2">{option.label}</span>
							</div>
							<p className="text-xs text-gray-600 mt-1 pl-6">{option.description}</p>
						</DropdownMenuItem>
					))}
				</motion.div>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default JobStatusBadge;
