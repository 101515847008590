import React from "react";

import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";
import Header from "../../Profile/components/Header";
import PaymentFlow from "./PaymentFlow";

function Payments() {
	const isLoggedIn = useIsLoggedIn();

	return (
		<div
			style={{
				backgroundColor: "red",
				margin: "0 10%",
				padding: "0px",
				"@media (maxWidth: 768px)": {
					width: "100%",
					margin: "0 10%",
				},
			}}
		>
			<Header isLoggedIn={isLoggedIn} />
			{/* <div className="container-lg first-div my-resumes-section">
		</div> */}
			<PaymentFlow />
		</div>
	);
}

export default Payments;
