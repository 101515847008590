const iconSize = 15;
export function getMyResumesRoutes(dispatch) {
	return [
		{
			type: "group",
			name: "Jobs",
		},
		{
			icon: "jobs",
			iconSize: iconSize,
			name: "Jobs",
			route: "/jobs",
			onClick: () => {},
		},
		{
			icon: "my-jobs",
			iconSize: iconSize,
			name: "My Jobs",
			route: "/profile",
			onClick: () => {},
		},

		{
			type: "group",
			name: "Professional Profile",
		},
		{
			icon: "create-resume",
			iconSize: iconSize,
			name: "Create Resume",
			route: "/resume/select-experience",
			onClick: () => {},
		},
		{
			icon: "job-preferences",
			iconSize: iconSize,
			name: "Job Preferences",
			route: "/profile",
			onClick: () => {},
		},
		{
			type: "group",
			name: "Other",
		},
		{
			icon: "job-preferences",
			iconSize: iconSize,
			name: "Settings",
			route: "/settings",
			onClick: () => {
				dispatch({ type: "SET_MyJOBS", payload: true });
			},
		},
		{
			icon: "rewards",
			iconSize: iconSize,
			name: "My Rewards",
			route: "/rewards",
			onClick: () => {},
		},
	];
}

export function getSettingssRoutes() {
	return [
		{
			type: "group",
			name: "User",
		},
		{
			icon: "account",
			iconSize: iconSize,
			name: "Profile",
			route: "/settings",
			onClick: () => {},
		},
		{
			type: "group",
			name: "Premium",
		},
		{
			icon: "subscription",
			iconSize: iconSize,
			name: "Subscription",
			route: "/subscription",
			onClick: () => {},
		},
		{
			icon: "make-payment",
			iconSize: iconSize,
			name: "Make Payment",
			route: "/payments",
			onClick: () => {},
		},
		{
			icon: "pending-payments",
			iconSize: iconSize,
			name: "Pending Payments",
			route: "/pending-payments",
			onClick: () => {},
		},
	];
}

export function getJobsRoutes() {
	return [
		{
			type: "group",
			name: "Companies",
		},
		{
			icon: "building",
			iconSize: iconSize,
			name: "Companies",
			route: "/job-market/companies",
			onClick: () => {},
		},
		{
			icon: "file",
			iconSize: iconSize,
			name: "Job Descriptions",
			route: "/job-market/jds",
			onClick: () => {},
		},
		{
			icon: "account",
			iconSize: iconSize,
			name: "Shared Leads",
			route: "/job-market/shared-leads",
			onClick: () => {},
		},
		{
			type: "group",
			name: "Jobs",
		},
		{
			icon: "folder",
			iconSize: iconSize,
			name: "Jobs",
			route: "/job-market",
			onClick: () => {},
		},
		{
			icon: "plus-circle",
			iconSize: iconSize,
			name: "Post Job without JD",
			route: "/job-market/post",
			onClick: () => {},
		},
	];
}
