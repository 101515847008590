import React, { useState } from "react";

import Drawer from "../../../components/Drawer/Drawer";
import ViewJob from "../../StaticPages/HomePage/components/ViewJob";
import { Card } from "./Card";
import JobListingItem from "./JobListingItem";

function SavedJobs({ jobs }) {
	const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	return (
		<>
			<Drawer
				isOpen={showJobDetailsModal}
				onClose={() => {
					setShowJobDetailsModal(false);
				}}
			>
				<ViewJob data={selectedJob} setData={setSelectedJob} />
			</Drawer>
			<div className="space-y-6">
				<Card className="w-full">
					<div className="px-4 py-2">
						<div className="divide-y divide-gray-200">
							{jobs?.map((job, index) => (
								<JobListingItem
									key={index}
									job={{
										...job,
										logo: job.company.logo_url,
									}}
									showCompany={true}
									hideSaveButton={true}
									deleteButton={true}
									onClick={() => {
										setSelectedJob(job);
										setShowJobDetailsModal(true);
									}}
									onApply={() => {
										setSelectedJob(job);
										setShowJobDetailsModal(true);
									}}
									titleClassName="text-sm font-semibold"
								/>
							))}
						</div>
					</div>
				</Card>
			</div>
		</>
	);
}

export default SavedJobs;
