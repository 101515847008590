import React from "react";

function Overview({ companyData }) {
	return (
		<div className="w-80 flex-shrink-0">
			<div className="bg-white rounded-lg p-6 mb-6 sticky top-4">
				<div className="space-y-6">
					<div>
						<h3 className="text-md font-semibold text-gray-950 mb-3">Company Overview</h3>
						<div className="space-y-3">
							<div className="flex justify-between">
								<span className="text-sm text-gray-600">Founded</span>
								<span className="text-sm text-gray-900">{companyData.founded}</span>
							</div>
							<div className="flex justify-between">
								<span className="text-sm text-gray-600">Team Size</span>
								<span className="text-sm text-gray-900">{companyData.company_size_approx}</span>
							</div>
							<div className="flex justify-between">
								<span className="text-sm text-gray-600">Industry</span>
								<span className="text-sm text-gray-900 text-right">{companyData.company_industry}</span>
							</div>
						</div>
					</div>
					{companyData.benefits?.length > 0 && (
						<div>
							<h3 className="text-sm font-medium text-gray-900 mb-3">Benefits &amp; Perks</h3>
							<ul className="space-y-2">
								{companyData.benefits.map((benefit, index) => (
									<li className="flex items-center space-x-2" key={index}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											width="24"
											height="24"
											color="#000000"
											fill="none"
											className="w-4 h-4 text-success-default"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M11.75 22.5C5.81294 22.5 1 17.6871 1 11.75C1 5.81294 5.81294 1 11.75 1C17.6871 1 22.5 5.81294 22.5 11.75C22.5 17.6871 17.6871 22.5 11.75 22.5ZM16.5182 9.39018C16.8718 8.9659 16.8145 8.33534 16.3902 7.98177C15.9659 7.62821 15.3353 7.68553 14.9818 8.10981L10.6828 13.2686L8.45711 11.0429C8.06658 10.6524 7.43342 10.6524 7.04289 11.0429C6.65237 11.4334 6.65237 12.0666 7.04289 12.4571L10.0429 15.4571C10.2416 15.6558 10.5146 15.7617 10.7953 15.749C11.076 15.7362 11.3384 15.606 11.5182 15.3902L16.5182 9.39018Z"
												fill="currentColor"
											/>
										</svg>
										<span className="text-sm text-gray-700">{benefit}</span>
									</li>
								))}
							</ul>
						</div>
					)}
					{false && (
						<div>
							<h3 className="text-sm font-medium text-gray-900 mb-3">Tech Stack</h3>
							<div className="flex flex-wrap gap-2">
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
									React
								</span>
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
									TypeScript
								</span>
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
									Node.js
								</span>
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
									Python
								</span>
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
									AWS
								</span>
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
									Docker
								</span>
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
									PostgreSQL
								</span>
								<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
									Redis
								</span>
							</div>
						</div>
					)}
					<div>
						<h3 className="text-sm font-medium text-gray-900 mb-3">Connect</h3>
						<div className="flex items-center space-x-4">
							{false && (
								<a href="#" className="text-gray-600 hover:text-gray-900">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="currentColor"
										className="w-5 h-5"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"
										/>
									</svg>
								</a>
							)}
							{companyData.linkedin_url && (
								<a
									href={companyData.linkedin_url}
									target="_blank"
									className="text-gray-600 hover:text-gray-900"
									rel="noreferrer"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										width="24"
										height="24"
										fill="none"
										className="w-5 h-5"
									>
										<path
											d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M6 9H2V21H6V9Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</a>
							)}
							{false && (
								<a href="#" className="text-gray-600 hover:text-gray-900">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										width="24"
										height="24"
										fill="none"
										className="w-5 h-5"
									>
										<path
											d="M22 4C22 4 21.3 6.1 20 7.4C21.6 17.4 10.6 24.7 2 19C4.2 19.1 6.4 18.4 8 17C3 15.5 0.5 9.6 3 5C5.2 7.6 8.6 9.1 12 9C11.1 4.8 16 2.4 19 5.2C20.1 5.2 22 4 22 4Z"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</a>
							)}
						</div>
					</div>
					<div>
						<h3 className="text-sm font-medium text-gray-900 mb-3">Location</h3>
						<div className="flex items-center space-x-2">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="currentColor"
								className="w-5 h-5 text-gray-600"
							>
								<path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
								/>
							</svg>
							<span className="text-sm text-gray-700">{companyData.headquarters}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Overview;
