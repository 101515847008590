import dayjs from "dayjs";
import React, { useEffect } from "react";

import SearchableMultiSelectInput from "../../../components/SearchableMultiSelectInput/SearchableMultiSelectInput";
import SearchableSelectInput from "../../../components/SearchableSelectInput/SearchableSelectInput";
import {
	checkObjectsHaveSameKeysAndValues,
	compareArray,
	generateYearArray,
	months,
} from "../../../handlers/utilityFunctions";
import dropDownOptions from "../../../seed/dropDownOptions";
import {
	educationLevelsAttained,
	jobSearchStatusOptions,
	jobTypes,
	recentGraduateValues,
	regions,
	relocatingForWork,
	seniorityLevels,
} from "../../../seed/job-preferences-data";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import { Button } from "../../JobsPage/components/Button";
import { Modal } from "./Modal";

const Tanzania = "Tanzania";

const JobPreferencesModal = ({
	isOpen,
	onClose,
	persistJobPreferences,
	jobPreferencesBody,
	setJobPreferences,
	setPersistJobPreferences,
	setJobPreferencesBody,
	jobPreferences,
	resumeList,
	onSave,
}) => {
	const resumeDataService = React.useMemo(() => new ResumeDataService(), []);
	const expertiseAreas = dropDownOptions.jobExpertiseOptions;
	const [submitting, setSubmitting] = React.useState(false);
	const [hasMadeChanges, setHasMadeChanges] = React.useState(false);
	const [inputSkillValue, setInputSkillValue] = React.useState("");
	const [errors, setErrors] = React.useState([]);

	const addSkill = () => {
		setJobPreferencesBody({
			...jobPreferencesBody,
			jobPreferences: {
				...jobPreferencesBody?.jobPreferences,
				skills: jobPreferencesBody?.jobPreferences?.skills
					? [...jobPreferencesBody.jobPreferences.skills, inputSkillValue.trim()]
					: [inputSkillValue.trim()],
			},
		});
		setJobPreferences({
			...jobPreferences,
			skills: jobPreferences?.skills ? [...jobPreferences.skills, inputSkillValue.trim()] : [inputSkillValue.trim()],
		});
		setInputSkillValue("");
	};

	const updateJobPreferences = () => {
		if (!submitting) {
			setSubmitting(true);
			resumeDataService.jobPreferences(
				jobPreferencesBody,
				(data) => {
					setJobPreferences(data?.data?.jobPreferences);
					setPersistJobPreferences(data?.data?.jobPreferences);
					localStorage.setItem("jobPreferences", JSON.stringify(data?.data));
					setErrors([]);
					setSubmitting(false);
					onSave();
				},
				(error) => {
					const errorMessages = error?.response?.data?.message;
					setErrors(errorMessages);
					setSubmitting(false);
				},
			);
		}
	};

	useEffect(() => {
		if (persistJobPreferences?.currentJobsearchStatus !== jobPreferences?.currentJobsearchStatus) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.waysToHelpme !== jobPreferences?.waysToHelpme) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.primaryResumeReferenceId !== jobPreferences?.primaryResumeReferenceId) {
			setHasMadeChanges(true);
		} else if (compareArray(persistJobPreferences?.openForWorkTypes, jobPreferences?.openForWorkTypes)) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.seniorityLevel !== jobPreferences?.seniorityLevel) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.locationPreferenceType !== jobPreferences?.locationPreferenceType) {
			setHasMadeChanges(true);
		} else if (compareArray(persistJobPreferences?.locationsPreferred, jobPreferences?.locationsPreferred)) {
			setHasMadeChanges(true);
		} else if (compareArray(persistJobPreferences?.areasOfExpertise, jobPreferences?.areasOfExpertise)) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.recentGraduate !== jobPreferences?.recentGraduate) {
			setHasMadeChanges(true);
		} else if (
			!checkObjectsHaveSameKeysAndValues(persistJobPreferences?.graduationDate, jobPreferences?.graduationDate)
		) {
			setHasMadeChanges(true);
		} else if (
			!checkObjectsHaveSameKeysAndValues(persistJobPreferences?.professionalSince, jobPreferences?.professionalSince)
		) {
			setHasMadeChanges(true);
		} else if (compareArray(persistJobPreferences?.educationLevels, jobPreferences?.educationLevels)) {
			setHasMadeChanges(true);
		} else if (compareArray(persistJobPreferences?.skills, jobPreferences?.skills)) {
			setHasMadeChanges(true);
		} else if (persistJobPreferences?.desiredTitle !== jobPreferences?.desiredTitle) {
			setHasMadeChanges(true);
		} else if (!checkObjectsHaveSameKeysAndValues(persistJobPreferences?.address, jobPreferences?.address)) {
			setHasMadeChanges(true);
		} else {
			setHasMadeChanges(false);
		}
	}, [persistJobPreferences, jobPreferences]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Job Preferences"
			size="large"
			primaryAction={
				hasMadeChanges
					? {
							label: submitting ? "Loading..." : "Save Preferences",
							onClick: updateJobPreferences,
						}
					: null
			}
			secondaryAction={{
				label: "Cancel",
				onClick: onClose,
			}}
		>
			<div className="space-y-8 max-h-[calc(100vh-200px)] overflow-y-auto pr-2">
				{(errors?.length || 0) > 0 && (
					<div className="bg-error-default text-white p-4 rounded-md mb-4">
						{Array.isArray(errors) ? errors.map((error, index) => <div key={index}>{error}</div>) : errors}
					</div>
				)}
				{/* Current Status */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">What’s your current job status?</h3>
					<SearchableSelectInput
						options={jobSearchStatusOptions}
						onSelect={(value) => {
							setJobPreferencesBody({
								...jobPreferencesBody,
								jobPreferences: {
									...jobPreferencesBody?.jobPreferences,
									currentJobsearchStatus: value,
								},
							});
							setJobPreferences({
								...jobPreferences,
								currentJobsearchStatus: value,
							});
						}}
						value={jobPreferencesBody?.jobPreferences?.currentJobsearchStatus}
						defaultLabel="Select job status"
					/>
				</div>

				{/* Areas of Expertise */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">What’s your area of expertise?</h3>
					<SearchableMultiSelectInput
						options={expertiseAreas.map((area) => {
							return { label: area, value: area };
						})}
						onSelect={(value) => {
							setJobPreferencesBody({
								...jobPreferencesBody,
								jobPreferences: {
									...jobPreferencesBody?.jobPreferences,
									areasOfExpertise: value,
								},
							});
							setJobPreferences({
								...jobPreferences,
								areasOfExpertise: value,
							});
						}}
						value={jobPreferences?.areasOfExpertise?.slice(0, 5) || []}
						placeholder="Select area of expertise"
					/>
				</div>

				{/* Skills */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">What are your superpower skills?</h3>
					<div className="flex flex-wrap gap-2 mb-2">
						{jobPreferences?.skills?.map((chip, index) => (
							<div key={index} className="flex items-center px-2 py-1 bg-blue-100 text-blue-800 rounded-full">
								{chip}
								<button
									type="button"
									onClick={() => {
										setJobPreferencesBody({
											...jobPreferencesBody,
											jobPreferences: {
												...jobPreferencesBody?.jobPreferences,
												skills: jobPreferencesBody?.jobPreferences?.skills.filter(
													(_, skillIndex) => skillIndex !== index,
												),
											},
										});
										setJobPreferences({
											...jobPreferences,
											skills: jobPreferences?.skills.filter((_, skillIndex) => skillIndex !== index),
										});
									}}
									className="text-blue-600 hover:text-blue-800 focus:outline-none"
								>
									&times;
								</button>
							</div>
						))}
					</div>
					<div className="flex items-center gap-4">
						<input
							type="text"
							placeholder="Add skill"
							value={inputSkillValue}
							onChange={(e) => setInputSkillValue(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter" && inputSkillValue.trim() !== "") {
									addSkill();
								}
							}}
							className="w-full border border-gray-300 rounded-md px-3 py-2 outline-none focus:ring-0"
						/>
						<Button
							onClick={() => {
								addSkill();
							}}
							disabled={inputSkillValue.trim() === ""}
							className="h-[36px] bg-primary-700 hover:bg-primary-800 text-white py-2 text-[14px] font-normal font-inter"
						>
							Add
						</Button>
					</div>
				</div>

				{/* Job Types */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">What type of roles are you looking for?</h3>
					<div className="flex flex-wrap gap-2">
						{jobTypes.map((type) => (
							<button
								key={type}
								onClick={() => {
									setJobPreferencesBody({
										...jobPreferencesBody,
										jobPreferences: {
											...jobPreferencesBody?.jobPreferences,
											openForWorkTypes: !jobPreferencesBody?.jobPreferences?.openForWorkTypes
												? [type]
												: jobPreferences?.openForWorkTypes?.includes(type)
													? jobPreferencesBody?.jobPreferences?.openForWorkTypes.filter((jobType) => jobType !== type)
													: [...jobPreferencesBody.jobPreferences.openForWorkTypes, type],
										},
									});
									setJobPreferences({
										...jobPreferences,
										openForWorkTypes: !jobPreferencesBody?.jobPreferences?.openForWorkTypes
											? [type]
											: jobPreferences?.openForWorkTypes?.includes(type)
												? jobPreferencesBody?.jobPreferences?.openForWorkTypes.filter((jobType) => jobType !== type)
												: [...jobPreferencesBody.jobPreferences.openForWorkTypes, type],
									});
								}}
								className={`ignore-focus-behavior px-4 py-2 rounded-full border text-[14px] font-inter
                  ${
										jobPreferences?.openForWorkTypes?.includes(type)
											? "border-primary-600 bg-primary-50 text-primary-700"
											: "hover:bg-gray-50 text-gray-700"
									}`}
							>
								{type}
							</button>
						))}
					</div>
				</div>

				{/* Relocating For Work */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">Are you open to relocating for work?</h3>
					<SearchableSelectInput
						options={relocatingForWork}
						onSelect={(value) => {
							setJobPreferencesBody({
								...jobPreferencesBody,
								jobPreferences: {
									...jobPreferencesBody?.jobPreferences,
									locationPreferenceType: value,
								},
							});
							setJobPreferences({
								...jobPreferences,
								locationPreferenceType: value,
							});
						}}
						value={jobPreferences?.locationPreferenceType ? jobPreferences?.locationPreferenceType : ""}
						defaultLabel="Select all that apply"
					/>
				</div>

				{/* Locations Preferred */}
				{jobPreferences?.locationPreferenceType === relocatingForWork[1].value && (
					<div className="space-y-3">
						<h3 className="text-[14px] font-medium font-inter text-gray-950">What’s your preferred work locations?</h3>
						<SearchableMultiSelectInput
							options={regions.map((item) => {
								return { label: item, value: item };
							})}
							onSelect={(value) => {
								setJobPreferencesBody({
									...jobPreferencesBody,
									jobPreferences: {
										...jobPreferencesBody?.jobPreferences,
										locationsPreferred: value,
									},
								});
								setJobPreferences({
									...jobPreferences,
									locationsPreferred: value,
								});
							}}
							value={jobPreferences?.locationsPreferred?.slice(0, 5) || []}
							placeholder="Select area of expertise"
						/>
					</div>
				)}

				{/* Recent Graduate */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">⁠Are you a recent graduate?</h3>
					<div className="flex flex-wrap gap-2">
						{recentGraduateValues.map((value) => (
							<button
								key={value}
								onClick={() => {
									setJobPreferencesBody({
										...jobPreferencesBody,
										jobPreferences: {
											...jobPreferencesBody?.jobPreferences,
											recentGraduate: value,
										},
									});
									setJobPreferences({
										...jobPreferences,
										recentGraduate: value,
									});
								}}
								className={`ignore-focus-behavior px-4 py-2 rounded-full border text-[14px] font-inter
                  ${
										jobPreferences?.recentGraduate === value
											? "border-primary-600 bg-primary-50 text-primary-700"
											: "hover:bg-gray-50 text-gray-700"
									}`}
							>
								{value}
							</button>
						))}
					</div>
				</div>

				{/* When Graduate */}
				{jobPreferences?.recentGraduate === recentGraduateValues[0] && (
					<div className="space-y-3">
						<h3 className="text-[14px] font-medium font-inter text-gray-950">
							When did you graduate or expect to graduate?
						</h3>
						<div className="flex gap-2">
							<SearchableSelectInput
								options={months.map((item, index) => {
									return { label: item, value: index + 1 };
								})}
								onSelect={(value) => {
									setJobPreferencesBody({
										...jobPreferencesBody,
										jobPreferences: {
											...jobPreferencesBody?.jobPreferences,
											graduationDate: { ...jobPreferencesBody?.jobPreferences?.graduationDate, month: value },
										},
									});
									setJobPreferences({
										...jobPreferences,
										graduationDate: { ...jobPreferences?.graduationDate, month: value },
									});
								}}
								value={jobPreferences?.graduationDate?.month || ""}
								defaultLabel="Select Month"
							/>
							<SearchableSelectInput
								options={generateYearArray().map((year) => {
									return { label: year.toString(), value: year };
								})}
								onSelect={(value) => {
									setJobPreferencesBody({
										...jobPreferencesBody,
										jobPreferences: {
											...jobPreferencesBody?.jobPreferences,
											graduationDate: { ...jobPreferencesBody?.jobPreferences?.graduationDate, year: value },
										},
									});
									setJobPreferences({
										...jobPreferences,
										graduationDate: { ...jobPreferences?.graduationDate, year: value },
									});
								}}
								value={jobPreferences?.graduationDate?.year || ""}
								defaultLabel="Select Year"
							/>
						</div>
					</div>
				)}

				{/* Professional Career Start */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">
						When did you start your professional career?
					</h3>
					<div className="flex gap-2">
						<SearchableSelectInput
							options={months.map((item, index) => {
								return { label: item, value: index + 1 };
							})}
							onSelect={(value) => {
								setJobPreferencesBody({
									...jobPreferencesBody,
									jobPreferences: {
										...jobPreferencesBody?.jobPreferences,
										professionalSince: { ...jobPreferencesBody?.jobPreferences?.professionalSince, month: value },
									},
								});
								setJobPreferences({
									...jobPreferences,
									professionalSince: { ...jobPreferences?.professionalSince, month: value },
								});
							}}
							value={jobPreferences?.professionalSince?.month || ""}
							defaultLabel="Select Month"
						/>
						<SearchableSelectInput
							options={generateYearArray().map((year) => {
								return { label: year.toString(), value: year };
							})}
							onSelect={(value) => {
								setJobPreferencesBody({
									...jobPreferencesBody,
									jobPreferences: {
										...jobPreferencesBody?.jobPreferences,
										professionalSince: { ...jobPreferencesBody?.jobPreferences?.professionalSince, year: value },
									},
								});
								setJobPreferences({
									...jobPreferences,
									professionalSince: { ...jobPreferences?.professionalSince, year: value },
								});
							}}
							value={jobPreferences?.professionalSince?.year || ""}
							defaultLabel="Select Year"
						/>
					</div>
				</div>

				{/* Seniority Level */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">What seniority level are you targeting?</h3>
					<SearchableSelectInput
						options={seniorityLevels}
						onSelect={(value) => {
							setJobPreferencesBody({
								...jobPreferencesBody,
								jobPreferences: {
									...jobPreferencesBody?.jobPreferences,
									seniorityLevel: value,
								},
							});
							setJobPreferences({
								...jobPreferences,
								seniorityLevel: value,
							});
						}}
						value={jobPreferences?.seniorityLevel ? jobPreferences?.seniorityLevel : ""}
						defaultLabel="Select all that apply"
					/>
				</div>

				{/* Currently Located */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">Where are you currently located?</h3>
					<SearchableSelectInput
						options={regions.map((item) => {
							const address = `${item},${Tanzania}`;

							return { label: address, value: address };
						})}
						onSelect={(value) => {
							const address = value.split(",");
							setJobPreferencesBody({
								...jobPreferencesBody,
								jobPreferences: {
									...jobPreferencesBody?.jobPreferences,
									address: {
										...jobPreferencesBody?.jobPreferences?.address,
										city: address?.[0] || "",
										country: address?.[1] || "",
									},
								},
							});
							setJobPreferences({
								...jobPreferences,
								address: { ...jobPreferences?.address, city: address[0] || "", country: address[1] || "" },
							});
						}}
						value={`${jobPreferences?.address?.city},${jobPreferences?.address?.country}` || ""}
						defaultLabel="Select your location"
					/>
				</div>

				{/* Education Levels Attained */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">What’s your education levels attained?</h3>
					<SearchableMultiSelectInput
						options={educationLevelsAttained}
						onSelect={(value) => {
							setJobPreferencesBody({
								...jobPreferencesBody,
								jobPreferences: {
									...jobPreferencesBody?.jobPreferences,
									educationLevels: value,
								},
							});
							setJobPreferences({
								...jobPreferences,
								educationLevels: value,
							});
						}}
						value={jobPreferences?.educationLevels || []}
						placeholder="Select education levels attained"
					/>
				</div>

				{/* Resume Selection */}
				<div className="space-y-3">
					<h3 className="text-[14px] font-medium font-inter text-gray-950">
						Which resume should be used for preferred jobs?
					</h3>
					<SearchableSelectInput
						options={resumeList.map((item) => {
							return {
								label: `${item.journal.resumeTitle || item.heading.resumeTitle} - Updated on ${dayjs(item.journal.lastUpdatedOn).format("DD MMMM, YYYY")}`,
								value: item._id,
							};
						})}
						onSelect={(value) => {
							setJobPreferencesBody({
								...jobPreferencesBody,
								jobPreferences: {
									...jobPreferencesBody?.jobPreferences,
									primaryResumeReferenceId: value,
								},
							});
							setJobPreferences({
								...jobPreferences,
								primaryResumeReferenceId: value,
							});
						}}
						value={jobPreferences?.primaryResumeReferenceId}
						defaultLabel="Select primary resume"
					/>
				</div>
			</div>
		</Modal>
	);
};

export default JobPreferencesModal;
