import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { CalendarIcon, Circle, DollarSign, MapPinIcon, Trash } from "../../../assets/icons/interface/interface";
import SaveButton from "../../../components/SaveButton";
import { cn } from "../../../handlers/cn.utils";
import { currencyFormat } from "../../../handlers/utilityFunctions";
import { useCompanies, useRecommendedJobs, useSavedJobs } from "../../../services/create-resume/queries";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

const JobListingItem = ({
	job,
	onApply,
	onClick,
	deleteButton = false,
	showCompany = false,
	titleClassName = "",
	hideSaveButton = false,
	showMatchScore = false,
	hideDate = false,
	hideCategory = false,
}) => {
	const jobService = React.useMemo(() => new ResumeDataService(), []);
	const { data, isLoading } = useCompanies();
	const [submitting, setSubmitting] = React.useState(false);
	const [deleting, setDeleting] = React.useState(false);
	const { refetch: refetchRecommendedJobs } = useRecommendedJobs();
	const { refetch: refetchSavedJobs } = useSavedJobs();

	const companyId = useMemo(() => {
		if (data && !isLoading) {
			let company = data.data.find((company) => company.company_name.includes(job.companyName));
			if (company) {
				return company._id;
			}
		}
		return job?.company?._id;
	}, [data, isLoading]);

	const isJobExpired = (jobCloseDate) => {
		const currentDate = new Date();
		const expDate = new Date(jobCloseDate);

		return currentDate > expDate;
	};

	const saveInteraction = async () => {
		setSubmitting(true);
		jobService.saveJobInteraction(
			job?._id,
			{ interactionType: "Saved" },
			() => {
				refetchRecommendedJobs();
				refetchSavedJobs();
				setSubmitting(false);
			},
			() => {
				setSubmitting(false);
			},
		);
	};

	const deleteJobInteraction = async () => {
		setDeleting(true);
		jobService.deleteJobInteraction(
			job?._id,
			() => {
				refetchRecommendedJobs();
				refetchSavedJobs();
				setDeleting(false);
			},
			() => {
				setDeleting(false);
			},
		);
	};

	useEffect(() => {}, [data, isLoading]);

	return (
		<div className="flex justify-between items-center py-4 border-gray-200 transition-colors duration-300 hover:bg-gray-50">
			<div className="flex items-center cursor-pointer">
				{job?.logo && (
					<Link to={companyId ? `/companies/${companyId}` : "#"} state={job?.company}>
						<div className="w-12 h-12 rounded-lg mr-4 flex items-center justify-center overflow-hidden border border-gray-100">
							<img
								src={job?.logo}
								alt={`${job?.company?.company_name} logo`}
								width={48}
								height={48}
								className="w-full h-full object-contain"
								onError={(e) => (e.target.src = "/images/co-placeholder.png")}
							/>
						</div>
					</Link>
				)}
				<div onClick={onClick}>
					<div className="flex items-center mb-1">
						<h3
							className={`${titleClassName || "text-base"} font-semibold mr-2 font-inter transition-colors duration-300 hover:text-primary-700 text-gray-900`}
						>
							{job?.jobTitle}
						</h3>
						{!hideCategory && job?.category && (
							<span className="text-xs px-2 rounded bg-gray-100 text-gray-800 h-5 flex items-center justify-center mr-2">
								{job?.category}
							</span>
						)}
						{job?.isPromoted && (
							<span className="text-xs px-2 rounded bg-blue-100 text-blue-800 h-5 flex items-center justify-center">
								Promoted
							</span>
						)}
						{isJobExpired(job.closeAt) && (
							<span className="text-xs px-2 rounded bg-red-100 text-red-800 h-5 flex items-center justify-center mr-2">
								Expired
							</span>
						)}
					</div>
					<p className="text-[14px] text-gray-600 flex items-center flex-wrap">
						{showMatchScore && job?.matchScore && (
							<span
								className={cn("inline-flex items-center h-6 px-2.5 py-0.5 rounded text-xs font-medium border mr-3", {
									"bg-green-100 text-green-800 border-green-200": job?.matchScore >= 80,
									"bg-blue-100 text-blue-800 border-blue-200": job?.matchScore >= 60 && job?.matchScore < 80,
									"bg-yellow-100 text-yellow-800 border-yellow-200": job?.matchScore < 60,
								})}
							>
								{job?.matchScore}% Match
							</span>
						)}
						{showCompany && <span className="mr-3">{job?.company?.company_name}</span>}
						<span className="flex items-center mr-3">
							<MapPinIcon className="w-4 h-4 mr-1" />
							{job?.jobLocation?.city}, {job?.jobLocation?.country}
						</span>
						{!hideDate && (
							<span className="flex items-center mr-3">
								<CalendarIcon className="w-4 h-4 mr-1" />
								{dayjs(`${job?.deadlineDate.year}-${job?.deadlineDate.month}-${job?.deadlineDate.day}`).format(
									"MMM DD, YYYY",
								)}
							</span>
						)}
						{!hideSaveButton && job?.salary && (
							<span className="flex items-center">
								<DollarSign className="w-4 h-4 mr-1" />
								{job?.salary?.minPay > 1 ? (
									<>
										{job?.salary?.payCurrency}{" "}
										{job.salary.payOption === "Exact Amount" ? (
											<>
												{currencyFormat(job?.salary?.minPay)} {job?.salary?.payFrequency}
											</>
										) : (
											<>
												{currencyFormat(job?.salary?.minPay)} - {job?.salary?.payCurrency}{" "}
												{currencyFormat(job?.salary?.maxPay)} {job?.salary?.payFrequency}
											</>
										)}
									</>
								) : (
									"Negotiable"
								)}
							</span>
						)}
					</p>
				</div>
			</div>
			<div className="flex items-center space-x-2">
				{!hideSaveButton && <SaveButton disabled={submitting} onClick={saveInteraction} />}
				{onApply && (
					<button
						onClick={onApply}
						className="inline-flex items-center justify-center bg-primary-700 text-white px-4 h-9 rounded hover:bg-primary-800 transition-colors duration-300 text-sm font-inter w-[90px] group relative overflow-hidden"
					>
						<span className="mr-1 group-hover:mr-3 transition-all duration-300">{`${isJobExpired(job.closeAt) ? "View" : "Apply"}`}</span>
						<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
							›
						</span>
					</button>
				)}
				{deleteButton && (
					<button
						disabled={deleting}
						onClick={() => {
							deleteJobInteraction();
						}}
						className="ml-2 text-gray-500 hover:text-red-600 hover:bg-red-50 hover:bg-accent hover:text-accent-foreground"
					>
						{deleting ? <Circle className="h-4 w-4" /> : <Trash className="h-4 w-4" />}
						<span className="sr-only">Remove from saved</span>
					</button>
				)}
			</div>
		</div>
	);
};

export default JobListingItem;
