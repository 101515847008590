import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { HomeIcon } from "../../../assets/icons/interface/interface";
import AuthContext from "../../../context/Auth/AuthContext";
import { Button } from "../../JobsPage/components/Button";
import JobStatusBadge from "./JobStatusBadge";

function Header({ isLoggedIn, variant = "default", currentJobStatus, onChangeJobStatus, onLogin }) {
	const { pathname } = useLocation();
	const [hoveredLink, setHoveredLink] = React.useState(null);
	const [backgroundStyles, setBackgroundStyles] = React.useState({});
	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
	const [isHamburgerHovered, setIsHamburgerHovered] = React.useState(false);
	const [isHamburgerFocused, setIsHamburgerFocused] = React.useState(false);
	const [isHamburgerPressed, setIsHamburgerPressed] = React.useState(false);
	const navRef = React.useRef(null);
	const navigate = useNavigate();

	// Add state for selected nav item
	const [, setSelectedNavItem] = React.useState("");

	const { picture } = useContext(AuthContext).userData || { picture: "" };

	// Navigation items for different header variants
	const defaultNavItems = [
		{ item: "Jobs", route: "/jobs" },
		{ item: "Companies", route: "/companies" },
		{ item: "Resume Tips", route: "/resume-tips" },
		{ item: "For Employers", route: "https://hiring.dproz.com/", type: "external" },
		{ item: "Blog", route: "/blog/job-hunt-challenges" },
		{ item: "About us", route: "/about-us" },
		{ item: "Plans", route: "/payments" },
	];
	const applicantNavItems = [
		{ item: "Profile", route: "/profile" },
		{ item: "Jobs", route: "/jobs" },
		{ item: "Applications", route: "/" },
	];

	// Toggle dropdown function
	const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

	const { setUserData } = useContext(AuthContext);
	const [__, _, removeCookie] = useCookies(["Authorization"]);

	// Handle profile click
	const handleProfileClick = () => {
		navigate("/profile");
		setIsDropdownOpen(false);
	};

	// Update selected nav item based on current path
	React.useEffect(() => {
		if (variant === "applicant") {
			const path = pathname.split("/").pop();
			if (path) {
				const formattedPath = path.charAt(0).toUpperCase() + path.slice(1);
				setSelectedNavItem(formattedPath);
			}
		} else {
			// For default variant
			const path = pathname.split("/")[1]; // Get the first segment after /
			if (path) {
				const formattedPath = path
					.split("-")
					.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
					.join(" ");
				setSelectedNavItem(formattedPath);
			}
		}
	}, [pathname, variant]);

	// Function to check if a nav item is currently selected
	const isNavItemSelected = (item) => {
		if (variant === "applicant") {
			return pathname === `/${item.toLowerCase()}`;
		} else {
			// For default variant
			if (item === "Jobs") {
				return pathname === "/jobs";
			}
			if (item === "Companies") {
				return pathname === "/companies";
			}
			// Add other path matches as needed
			return pathname === `/${item.toLowerCase().replace(" ", "-")}`;
		}
	};

	// Effect to handle the hover animation for nav items
	React.useEffect(() => {
		if (hoveredLink && navRef.current) {
			const linkElement = navRef.current.querySelector(`[data-name="${hoveredLink}"]`);
			if (linkElement) {
				const { offsetLeft, offsetWidth } = linkElement;
				setBackgroundStyles({
					left: `${offsetLeft}px`,
					width: `${offsetWidth}px`,
					opacity: 1,
				});
			}
		} else {
			setBackgroundStyles({ opacity: 0 });
		}
	}, [hoveredLink]);

	// Render the default variant of the header
	if (variant === "default") {
		return (
			<header className="h-16 bg-white flex items-center justify-between px-4 md:px-8 border-b border-gray-200">
				<div className="flex items-center w-1/3 md:w-1/6">
					<Link
						to="/"
						className="text-2xl font-bold text-gray-800 hover:text-gray-900 transition-colors font-plus-jakarta"
					>
						<img src="/images/logo.png" alt="Dproz logo" className="w-24" />
					</Link>
				</div>

				<nav ref={navRef} className="hidden md:flex justify-center space-x-2 w-2/3 relative">
					<motion.div
						className="absolute bg-gray-100 rounded-md z-0"
						initial={false}
						animate={{
							...backgroundStyles,
							height: "36px",
							top: "50%",
							y: "-50%",
						}}
						transition={{ type: "spring", stiffness: 500, damping: 30 }}
					/>
					{defaultNavItems.map((item) => (
						<Button
							key={item.item}
							variant="ghost"
							asChild
							className={`h-9 relative z-10 ${isNavItemSelected(item.item) ? "bg-gray-100" : ""}`}
						>
							<Link
								to={item.route}
								target={item.type === "external" ? "_blank" : ""}
								className={`font-inter text-[14px] font-medium transition-colors rounded-md px-3 py-2 ${
									isNavItemSelected(item.item) ? "text-gray-900" : "text-gray-700 hover:text-gray-900"
								}`}
								onMouseEnter={() => setHoveredLink(item.item)}
								onMouseLeave={() => setHoveredLink(null)}
								data-name={item.item}
							>
								{item.item}
							</Link>
						</Button>
					))}
				</nav>

				<div className="flex items-center justify-end space-x-2 w-2/3 md:w-1/6">
					{isLoggedIn ? (
						<div className="relative">
							<Button onClick={toggleDropdown} variant="ghost" className="flex items-center space-x-2">
								{/* Hamburger Icon */}
								<div className="w-4 h-4 flex flex-col justify-between">
									<span className="w-full h-0.5 bg-gray-800 rounded-full" />
									<span className="w-full h-0.5 bg-gray-800 rounded-full" />
									<span className="w-full h-0.5 bg-gray-800 rounded-full" />
								</div>
								{/* Profile Image */}
								<div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
									<img
										src={picture}
										alt="User profile"
										width={32}
										height={32}
										className="rounded-full object-cover"
										placeholder="blur"
									/>
								</div>
							</Button>

							{/* Dropdown menu */}
							{isDropdownOpen && (
								<div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50 top-full">
									<Button variant="ghost" asChild className="w-full justify-start">
										<Link to="/my-resumes">My Resumes</Link>
									</Button>
									<Button variant="ghost" onClick={handleProfileClick} className="w-full justify-start">
										Profile
									</Button>
									<Button variant="ghost" asChild className="w-full justify-start">
										<Link to="/settings">Settings</Link>
									</Button>
									<Button variant="ghost" asChild className="w-full justify-start">
										<Link
											onClick={() => {
												removeCookie("Authorization");
												localStorage.removeItem("profileData");
												setUserData(null);
												window.location.href = "/";
											}}
										>
											Logout
										</Link>
									</Button>
								</div>
							)}
						</div>
					) : (
						<div className="flex items-center space-x-2">
							<Button variant="default" className="bg-primary-700 hover:bg-primary-800 text-white h-9" asChild>
								<Link to="/resume/select-experience" className="font-inter text-[14px] font-medium">
									Create Resume
								</Link>
							</Button>
							<Button
								variant="ghost"
								onClick={onLogin}
								className="h-9 font-inter text-[14px] font-medium transition-colors"
							>
								<Link to="/signin">Login</Link>
							</Button>
						</div>
					)}
				</div>
			</header>
		);
	}

	// Render the applicant variant of the header
	if (variant === "applicant") {
		return (
			<header className="h-16 bg-white flex items-center justify-between px-4 md:px-8">
				<div className="flex items-center space-x-4">
					{/* Home Icon */}
					<Link to="/" passHref>
						<Button variant="ghost" className="p-2">
							<HomeIcon className="w-6 h-6 text-gray-600 hover:text-gray-900" />
						</Button>
					</Link>

					{/* Navigation links */}
					<nav ref={navRef} className="flex justify-start space-x-2 relative">
						<motion.div
							className="absolute bg-gray-100 rounded-md z-0"
							initial={false}
							animate={{
								...backgroundStyles,
								height: "36px",
								top: "50%",
								y: "-50%",
							}}
							transition={{ type: "spring", stiffness: 500, damping: 30 }}
						/>
						{applicantNavItems.map((item) => (
							<Button
								key={item.item}
								variant="ghost"
								asChild
								className={`h-9 relative z-10 ${isNavItemSelected(item.item) ? "bg-gray-100" : ""}`}
							>
								<Link
									to={item.route}
									className={`font-inter text-[14px] font-medium transition-colors rounded-md px-3 py-2 ${
										isNavItemSelected(item.item) ? "text-gray-900" : "text-gray-700 hover:text-gray-900"
									}`}
									onMouseEnter={() => setHoveredLink(item.item)}
									onMouseLeave={() => setHoveredLink(null)}
									onClick={() => setSelectedNavItem(item.item)}
									data-name={item.item}
								>
									{item.item}
								</Link>
							</Button>
						))}
					</nav>
				</div>

				<div className="flex items-center">
					{/* Job Status Badge */}
					<div className="mr-4">
						<JobStatusBadge
							initialStatus={currentJobStatus || "unknown"}
							onStatusChange={(status) => onChangeJobStatus(status)}
						/>
					</div>

					<Button
						onClick={toggleDropdown}
						variant="ghost"
						className={`flex items-center space-x-3 h-9 relative z-10 ${isHamburgerHovered ? "bg-gray-100" : ""} ${
							isHamburgerFocused ? "ring-2 ring-primary-500" : ""
						} ${isHamburgerPressed ? "bg-gray-200" : ""}`}
						onMouseEnter={() => setIsHamburgerHovered(true)}
						onMouseLeave={() => setIsHamburgerHovered(false)}
						onFocus={() => setIsHamburgerFocused(true)}
						onBlur={() => setIsHamburgerFocused(false)}
						onMouseDown={() => setIsHamburgerPressed(true)}
						onMouseUp={() => setIsHamburgerPressed(false)}
					>
						{/* Hamburger Icon - visible on all screen sizes */}
						<div className="w-4 h-4 flex flex-col justify-between">
							<span className="w-full h-0.5 bg-gray-800 rounded-full" />
							<span className="w-full h-0.5 bg-gray-800 rounded-full" />
							<span className="w-full h-0.5 bg-gray-800 rounded-full" />
						</div>
						{/* Profile Image */}
						<div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
							<img
								src={picture}
								alt="User profile"
								width={32}
								height={32}
								className="rounded-full object-cover"
								placeholder="blur"
							/>
						</div>
					</Button>

					{/* Dropdown menu */}
					{isDropdownOpen && (
						<div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 top-16">
							<Button variant="ghost" asChild className="w-full justify-start">
								<Link to="/my-resumes">My Resumes</Link>
							</Button>
							<Button variant="ghost" onClick={handleProfileClick} className="w-full justify-start">
								Profile
							</Button>
							<Button variant="ghost" asChild className="w-full justify-start">
								<Link to="/settings">Settings</Link>
							</Button>
							<Button variant="ghost" asChild className="w-full justify-start">
								<Link
									onClick={() => {
										removeCookie("Authorization");
										localStorage.removeItem("profileData");
										setUserData(null);
										window.location.href = "/";
									}}
								>
									Logout
								</Link>
							</Button>
						</div>
					)}
				</div>
			</header>
		);
	}
}

export default Header;
