import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { decodeToken } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, Navigate, Route, Routes, useLocation } from "react-router-dom";

import RouteChangeTracker from "../../RouteChangeTracker";
import ResumerBuilderLayout from "../components/ResumeBuilder/ResumeBuilderLayout";
import ResumeContext from "../context/ResumeContext";
import { logToConsole } from "../handlers/utilityFunctions";
import { resumeObjectToContextFormat } from "../handlers/valueTransformation";
import ExpertViewLayout from "../layouts/ExpertViewLayout";
import { StaticValue } from "../seed/constants";
import { ResumeDataService } from "../services/create-resume/resume-data";
import GraduateChallenges from "../Views/Articles/GraduateChallenges";
import CreatingProfileScreen from "../Views/Auth/CreatingProfileScreen";
import ForgotPasswordScreen from "../Views/Auth/ForgotPasswordScreen";
import RegisterScreen from "../Views/Auth/RegisterScreen";
import ProfileInfo from "../Views/Auth/RegisterScreen/ProfileInfo";
import SigninScreen from "../Views/Auth/SigninScreen";
import { Companies, Company } from "../Views/Companies";
import CreateResumeOptions from "../Views/CreateResumeOptions";
import CandidateHomeDashboard from "../Views/Dashboard/CandidateHomeDashboard";
import EmployerLandingPage from "../Views/EmployerLandingPage";
import ExpertHelpExistingUserUpload from "../Views/ExpertHelp/ExpertHelpExistingUserUpload";
import ExpertHelpLogin from "../Views/ExpertHelp/ExpertHelpLogin";
import ExpertHelpPersonalInfo from "../Views/ExpertHelp/ExpertHelpPersonalInfo";
import ExpertHelpProfessionalSummary from "../Views/ExpertHelp/ExpertHelpProfessionalSummary";
import ExpertHelpUploadResume from "../Views/ExpertHelp/ExpertHelpUploadResume";
import Pricing from "../Views/ExpertHelp/Pricing";
import ResumeCreationOptions from "../Views/ExpertHelp/ResumeCreationOptions";
import ExpertOrderDetails from "../Views/ExpertOrderDetails/ExpertOrderDetails";
import ExpertOrdersManagement from "../Views/ExpertOrdersManagement/ExpertOrdersManagement";
import ExpertOrdersOverview from "../Views/ExpertOrdersOverview/ExpertOrdersOverview";
import FAQ from "../Views/FAQ";
import { default as JMCompanies } from "../Views/JobMarket/Companies";
import JobDescription from "../Views/JobMarket/JobDescription";
import JobDescriptions from "../Views/JobMarket/JobDescriptions/";
import JobLandingPage from "../Views/JobMarket/JobLandingPage";
import Jobs from "../Views/JobMarket/Jobs";
import JobDetails from "../Views/JobMarket/Jobs/JobDetails";
import JobPostForm from "../Views/JobPostForm/JobPostForm";
import JobIndex from "../Views/Jobs/JobIndex";
import JobListing from "../Views/Jobs/JobListing";
import JobPreferences from "../Views/Jobs/JobPreferences";
import JobsPage from "../Views/JobsPage";
import SpecificJobDetails from "../Views/JobsPage/SpecificJobDetails";
import LandingPage from "../Views/LandingPage";
import MyResumes from "../Views/ManageResumes/MyResumes";
import Payments from "../Views/ManageResumes/Payment-2";
import PendingPayments from "../Views/ManageResumes/PendingPayments";
import Settings from "../Views/ManageResumes/Settings";
import SubscriptionPage from "../Views/ManageResumes/SubscriptionPage";
import PrivacyPolicy from "../Views/PrivacyPolicy";
import Profile from "../Views/Profile/Profile";
import ResumeEducationInformation from "../Views/ResumeBuilder/Education/ResumeEducationInformation";
import ResumeEducationSummary from "../Views/ResumeBuilder/Education/ResumeEducationSummary";
import ResumeWorkHistory from "../Views/ResumeBuilder/Experience/ResumeWorkHistory";
import WorkHistorySummary from "../Views/ResumeBuilder/Experience/WorkHistorySummary";
import ResumeContactInfo from "../Views/ResumeBuilder/Heading/ResumeContactInfo";
import Accomplishments from "../Views/ResumeBuilder/ResumeExtras/Accomplishments";
import AdditionalInformation from "../Views/ResumeBuilder/ResumeExtras/AdditionalInforamtion";
import Affiliations from "../Views/ResumeBuilder/ResumeExtras/Affiliations";
import Certifications from "../Views/ResumeBuilder/ResumeExtras/Certifications";
import CustomExtras from "../Views/ResumeBuilder/ResumeExtras/CustomExtras";
import Extras from "../Views/ResumeBuilder/ResumeExtras/Extras";
import Languages from "../Views/ResumeBuilder/ResumeExtras/Languages";
import PersonalLinks from "../Views/ResumeBuilder/ResumeExtras/PersonalLinks";
import PersonalProfile from "../Views/ResumeBuilder/ResumeExtras/PersonalProfile/PersonalProfile";
import ReferenceInformation from "../Views/ResumeBuilder/ResumeExtras/Reference/ReferenceInformation";
import ReferenceSummary from "../Views/ResumeBuilder/ResumeExtras/Reference/ReferenceSummary";
import ResumePreviewModal from "../Views/ResumeBuilder/ResumePreviewModal";
import Review from "../Views/ResumeBuilder/Review";
import ViewPdf from "../Views/ResumeBuilder/Review/viewPdf";
import ResumeSkills from "../Views/ResumeBuilder/Skills";
import ResumeBriefSummary from "../Views/ResumeBuilder/Summary";
import CandidateReview from "../Views/Reviews/CandidateReview";
import ResumeReviewDashboard from "../Views/Reviews/Dashboard";
import ExpertReview from "../Views/Reviews/ExpertReview";
import Rewards from "../Views/Rewards/Rewards";
import SelectExperience from "../Views/SelectExperience/SelectExperience";
import SelectResumeTemplate from "../Views/SelectResumeTemplate/SelectResumeTemplate";
import SharedLeads from "../Views/SharedLeads";
import AboutUs from "../Views/StaticPages/AboutUs";
import ResumeTips from "../Views/StaticPages/ResumeTips";
import TermsAndConditions from "../Views/TermsAndConditions";
import TodayILearned from "../Views/TodayILearned/TodayILearned";
import ManageStylesheets from "./ManageStylesheets";
import resume_data from "./resumedata";

function RouteHandler({ userData }) {
	const history = useLocation();

	useEffect(() => {}, [history]);

	useEffect(() => {}, [userData]);

	const template = useSelector((state) => state.TemplateSelectionReducer);
	resume_data.selectedTemplate = template.selectedTemplate;

	const [resumeData, setResumeData] = useState(resume_data);
	const [cookies] = useCookies(["resume"]);

	const staticValue = new StaticValue();
	const getStaticValues = staticValue.getDeafaulValues();
	const resumeKey = getStaticValues.localStorageResumeId;
	const resumeService = new ResumeDataService(); //singletone
	let resumeObject;
	let dispatch = new useDispatch();

	function fetchResumeData(resumeId, callback) {
		if (resumeId)
			resumeService.getResumeLedgerById(
				resumeId,
				(data) => {
					succeResume(data);
					if (callback) callback();
				},
				errorResume,
			);
	}

	useEffect(() => {
		const resumeId = cookies[resumeKey];

		const cookie = new Cookies();
		const auth = cookie.get("Authorization")
			? cookie.get("Authorization").replaceAll("GO::", "").replaceAll("CO::", "")
			: null;

		const isExpired = auth ? decodeToken(auth).exp < Date.now() / 1000 : true;

		if (!isExpired) {
			fetchResumeData(resumeId);
		}
	}, []);

	const dispatchAddExtras = (payload) => {
		dispatch({ type: "ADD_EXTRAS", payload: payload });
	};

	function succeResume(resume) {
		resumeObject = resume.data;
		resumeObjectToContextFormat(resumeObject, setResumeData, dispatchAddExtras);
	}
	function errorResume(error) {
		logToConsole(error);
	}

	useEffect(() => {}, [resumeData]);

	useEffect(() => {}, [history]);

	return (
		<ResumeContext.Provider value={{ resumeData, setResumeData, fetchResumeData }}>
			<Routes>
				<Route path="resume/select-option" element={<ResumeCreationOptions />} />
				<Route path="expert-help" element={<ExpertViewLayout />}>
					<Route path="login" element={<ExpertHelpLogin />} />
					<Route path="personal-info" element={<ExpertHelpPersonalInfo />} />
					<Route path="summary" element={<ExpertHelpProfessionalSummary />} />
					<Route path="upload-resume" element={<ExpertHelpUploadResume />} />
					<Route path="select-resume" element={<ExpertHelpExistingUserUpload />} />
				</Route>
				<Route path="expert-help/pricing" element={<Pricing />} />
				<Route path="resume" element={<ResumerBuilderLayout />}>
					<Route path="select-template" element={<SelectResumeTemplate />} />
					<Route path="create-resume-options" element={<CreateResumeOptions />} />
					<Route path="select-experience" element={<SelectExperience />} />
					<Route path="contact-information" element={<ResumeContactInfo />} />
					<Route path="work-history" element={<ResumeWorkHistory />} />
					<Route path="work-history-summary" element={<WorkHistorySummary />} />
					<Route path="education-information" element={<ResumeEducationInformation />} />
					<Route path="education-summary" element={<ResumeEducationSummary />} />
					<Route path="skills" element={<ResumeSkills />} />
					<Route path="brief-summary" element={<ResumeBriefSummary />} />
					<Route path="extras" element={<Extras />} />
					<Route path="accomplishments" element={<Accomplishments />} />
					<Route path="affiliations" element={<Affiliations />} />
					<Route path="personal-links" element={<PersonalLinks />} />
					<Route path="certifications" element={<Certifications />} />
					<Route path="additional-information" element={<AdditionalInformation />} />
					<Route path="languages" element={<Languages />} />
					<Route path="personal-profile" element={<PersonalProfile />} />
					<Route path="reference-information" element={<ReferenceInformation />} />
					<Route path="reference-summary" element={<ReferenceSummary />} />
					<Route path="custom-extra/:customExtra" element={<CustomExtras />} />
				</Route>
				<Route path="jobs" element={<JobsPage />} />
				<Route path="jobs/:id" element={<SpecificJobDetails />} />
				<Route path="companies" element={<Companies />} />
				<Route path="companies/:id" element={<Company />} />
				{/* Resume Review */}
				<Route path="review-resume" element={<ExpertReview />} />
				<Route path="expert-orders-overview" element={<ExpertOrdersOverview />} />
				<Route path="expert-orders-management" element={<ExpertOrdersManagement />} />
				<Route path="expert-order-details" element={<ExpertOrderDetails />} />
				<Route path="today-i-learned" element={<TodayILearned />} />
				<Route path="" element={<LandingPage />} />
				{userData ? (
					<>
						<Route path="profile" element={<Profile />} />

						<Route path="jobs-listing" element={<JobListing />} />
						<Route path="jobs-preference" element={<JobPreferences />} />

						<Route path="jobs/shared" element={<JobIndex />} />
						<Route path="review-dashboard" element={<ResumeReviewDashboard />} />
						<Route path="dashboard" element={<CandidateHomeDashboard />} />
						<Route path="candidate-review" element={<CandidateReview />} />
						<Route path="settings" element={<Settings />} />
						{/* <Route path="payments-old" element={<PaymentsOld />} /> */}
						<Route path="payments" element={<Payments />} />
						<Route path="pending-payments" element={<PendingPayments />} />
						<Route path="/my-resumes" element={<MyResumes />} />
						<Route path="/resume/review" element={<Review />} />

						<Route path="view-pdf" element={<ViewPdf />} />
						<Route path="/job-market/" element={<Jobs />} />
						<Route path="/job-market/companies" element={<JMCompanies />} />
						<Route path="/job-market/companies/post" element={<Jobs />} />
						<Route path="/job-market/job/:id" element={<JobDetails />} />
						<Route path="/job-market/post" element={<JobPostForm />} />
						<Route path="/job-market/jds" element={<JobDescriptions />} />
						<Route path="/job-market/jds/:id" element={<JobDescription />} />
						<Route path="/job-market/post/:id" element={<JobPostForm />} />
						<Route path="/job-market/shared-leads" element={<SharedLeads />} />
						<Route path="/rewards" element={<Rewards />} />
						<Route path="*" element={<Navigate to="/" />} />
					</>
				) : (
					<>
						<Route path="register" element={<ProfileInfo />} />
						<Route path="register/create-account" element={<RegisterScreen />} />
						<Route path="signin" element={<SigninScreen />} />
						<Route path="forgot-password" element={<ForgotPasswordScreen />} />

						<Route path="*" element={<Navigate to="/signin" />} />
					</>
				)}
				<Route path="/creating-profile" element={<CreatingProfileScreen />} />
				{/* Articles */}
				<Route path="/blog" element={<Navigate to="/blog/job-hunt-challenges" />} />
				<Route path="/blog/job-hunt-challenges" element={<GraduateChallenges />} />
				<Route path="employer-landing" element={<EmployerLandingPage />} />
				<Route path="about-us" element={<AboutUs />} />
				<Route path="resume-tips" element={<ResumeTips />} />
				<Route path="terms-and-conditions" element={<TermsAndConditions />} />
				<Route path="privacy-policy" element={<PrivacyPolicy />} />
				<Route path="subscription" element={<SubscriptionPage />} />
				<Route path="faq" element={<FAQ />} />
				<Route path="payments" element={<Payments />} />
				<Route path="/land-21-things/" element={<JobLandingPage />} />
				{/* 404 */}
			</Routes>
			<RouteChangeTracker history={history} />
			<ManageStylesheets />

			{() => {
				const isExcluded = [
					"/",
					"/jobs",
					"/companies",
					"/companies/:id",
					"/jobs/:id",
					"/profile",
					"/register",
					"/register/create-account",
					"/signin",
					"/creating-profile",
				].some((path) => matchPath(path, window.location.pathname));
				return isExcluded ? <ResumePreviewModal resumeData={resumeData} /> : null;
			}}
		</ResumeContext.Provider>
	);
}

export default RouteHandler;
