import { match } from "../handlers/utilityFunctions";

export const jobSearchStatusOptions = [
	{
		value: "Actively Looking",
		label: "Actively Looking",
	},
	{
		value: "Open for Reach outs",
		label: "Open for Reach outs",
	},
	{
		value: "Not Looking",
		label: "Not Looking",
	},
];

export const waysToHelpOptions = [
	{
		value: "Show me the right Jobs to Apply",
		description: "Recommend jobs related to your career",
		classIcon: "fa fa-user text-primary",
	},
	{
		value: "Recommend me to Employers",
		description: "Suggests to employers that you fit to particular jobs",
		classIcon: "fa fa-user text-success",
	},
	{
		value: "Strengthen my CVs",
		description: "Improving the quality and proffesion of your CVs",
		classIcon: "fa fa-user text-danger",
	},
];

export const locationTypes = [
	{
		value: "Open to Work Anywhere",
		description: "Jobs in all country regions will be recommended",
		classIcon: "fa fa-map-marker text-primary",
	},
	{
		value: "Open to Work in select Regions",
		description: "Only jobs selected to particular regions will be recommended",
		classIcon: "fa fa-map-marker text-success",
	},
];

export const relocatingForWork = [
	{ label: "Open to Relocate Anywhere", value: "Open to Relocate Anywhere" },
	{ label: "Open to Specific Regions", value: "Open to Specific Regions" },
	{ label: "Not Open to Relocation", value: "Not Open to Relocation" },
];

export const regions = [
	"Arusha",
	"Dar Es Salaam",
	"Dodoma",
	"Geita",
	"Iringa",
	"Kagera",
	"Katavi",
	"Kigoma",
	"Kilimanjaro",
	"Lindi",
	"Manyara",
	"Mara",
	"Mbeya",
	"Mjini Magharibi",
	"Morogoro",
	"Mtwara",
	"Mwanza",
	"Njombe",
	"Pemba North",
	"Pemba South",
	"Pwani",
	"Rukwa",
	"Ruvuma",
	"Shinyanga",
	"Simiyu",
	"Singida",
	"Songwe",
	"Tabora",
	"Tanga",
	"Unguja North",
	"Unguja South",
];

export const jobTypes = ["Full-Time", "Part-Time", "Contractor", "Temporary", "Internship", "Other"];

export const problemsForYou = ["Finding companies who are interested in me", "Finding companies that I want to join"];

export const roleAttributes = [
	"Antonomy",
	"Salary",
	"Mentorship",
	"Equity",
	"Impressive Team Members",
	"Highly Collaborative",
	"Company mission",
	"Opportunities for Growth",
	"To wear many hats",
];

export const companyPreferences = [
	"Small startups",
	"Growing team",
	"Medium Startups",
	"Big Startups",
	"Large companies",
];

export const seniorityLevels = [
	{
		value: "Entry-level(0-1 yr)",
		label: "Entry-level(0-1 yr)",
	},
	{
		value: "Junior-level(1-2 yrs)",
		label: "Junior-level(1-2 yrs)",
	},
	{
		value: "Mid-level(3-5 yrs)",
		label: "Mid-level(3-5 yrs)",
	},
	{
		value: "Senior-level(6+ yrs)",
		label: "Senior-level(6+ yrs)",
	},
];

export const recentGraduateValues = ["Yes", "No"];

export const educationLevelsAttained = [
	{ value: "O-Level", label: "Secondary Education" },
	{ value: "A-Level", label: "Advanced Secondary Education" },
	{ value: "CERTIFICATE", label: "Certificate" },
	{ value: "DIPLOMA", label: "Diploma" },
	{ value: "ADVANCED DIPLOMA", label: "Advanced Diploma" },
	{ value: "BACHELOR", label: "Bachelor" },
	{ value: "POST GRADUATE CERTIFICATE", label: "Post Graduate Certificate" },
	{ value: "POST GRADUATE DIPLOMA", label: "Post Graduate Diploma" },
	{ value: "MASTERS", label: "Masters" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Medical Doctor", label: "Medical Doctor" },
	{ value: "PhD", label: "PhD" },
];

export function jobPreferencesTitles(key) {
	return match(key, {
		locationsPreferred: "Location Preferences",
		currentJobsearchStatus: "Current Job Status",
		waysToHelpme: "Ways To Help",
		primaryResumeReferenceId: "Select Primary Resume",
		openForWorkTypes: "Job Types",
		seniorityLevel: "Seniority Level",
		areasOfExpertise: "Areas Of Expertise",
		locationPreferenceType: "Preferred Work Location",
		default: key,
	});
}
