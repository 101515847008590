import "../css/global.css";
import "../css/home.css";
import "../css/responsive.css";

import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import AuthContext from "../../../context/Auth/AuthContext";
import { cn } from "../../../handlers/cn.utils";
import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";

function Header({ activeLink }) {
	const userLoggedIn = useIsLoggedIn();

	const [activeAboutUsLink, setActiveAboutUsLink] = useState(false);
	const [activeResumeTipsLink, setActiveResumeTipsLink] = useState(false);

	const { setUserData } = useContext(AuthContext);

	useEffect(() => {
		if (activeLink === "home") {
			setActiveAboutUsLink(false);
			setActiveResumeTipsLink(false);
		}

		if (activeLink === "about") {
			setActiveAboutUsLink(true);
			setActiveResumeTipsLink(false);
		}

		if (activeLink === "tips") {
			setActiveAboutUsLink(false);
			setActiveResumeTipsLink(true);
		}
	}, []);

	const [showMobileMenu, setShowMobileMenu] = useState(false);

	function toggleMenu() {
		setShowMobileMenu(!showMobileMenu);
	}

	const [__, _, removeCookie] = useCookies(["Authorization"]);

	const [open, setOpen] = React.useState(false);
	const dropdownRef = React.useRef();

	React.useEffect(() => {
		const handleClick = (e) => {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClick);

		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	return (
		<header className={cn("bg-white shadow-sm", { "shadow-none": showMobileMenu })}>
			<div className="container">
				<div className="flex items-center justify-between py-3 md:px-0 px-3">
					<Link to="/" className="logo mr-2">
						<img src="/images/home/logo.svg" alt="Dproz logo" />
					</Link>

					<div
						className={cn({
							"flex flex-col z-50 items-start px-2 absolute bg-white w-full top-[60px] shadow-sm pb-6 left-0":
								showMobileMenu,
							"hidden md:flex": !showMobileMenu,
						})}
					>
						<ul
							className={cn("flex items-center justify-evenly 2xl:gap-4", {
								"flex-col justify-start w-full items-start gap-4 z-[500]": showMobileMenu,
							})}
						>
							<li
								className={cn({
									"w-full": showMobileMenu,
								})}
							>
								<Link
									to="/jobs"
									className={cn("btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full", {
										"bg-primary-100 text-primary-700": activeLink === "jobs",
									})}
								>
									Jobs
								</Link>
							</li>
							<li
								className={cn({
									"w-full": showMobileMenu,
								})}
							>
								<Link
									to="/companies"
									className={cn("btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full", {
										"bg-primary-100 text-primary-700": activeLink === "companies",
									})}
								>
									Companies
								</Link>
							</li>

							<li
								className={cn({
									"w-full": showMobileMenu,
								})}
							>
								<Link
									to="/resume-tips"
									className={cn("btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full", {
										"bg-primary-100 text-primary-700": activeResumeTipsLink,
									})}
								>
									Resume Tips
								</Link>
							</li>

							<li
								className={cn({
									"w-full": showMobileMenu,
								})}
							>
								<a
									href="https://hiring.dproz.com/"
									className="btn text-gray-800 font-medium"
									target="_blank"
									rel="noreferrer"
								>
									For Employers
								</a>
							</li>

							<li
								className={cn({
									"w-full": showMobileMenu,
								})}
							>
								<Link
									to="/blog/job-hunt-challenges"
									className={cn("btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full", {
										"bg-primary-100 text-primary-700": window.location.pathname.includes("/blog"),
									})}
								>
									Blog
								</Link>
							</li>
							<li
								className={cn({
									"w-full": showMobileMenu,
								})}
							>
								<Link
									to="/about-us"
									className={cn("btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full", {
										"bg-primary-100 text-primary-700": activeAboutUsLink,
									})}
								>
									About us
								</Link>
							</li>
							<li
								className={cn({
									"w-full": showMobileMenu,
								})}
							>
								<Link
									to="/payments"
									className={cn("btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full", {
										"bg-primary-100 text-primary-700": activeLink === "plans",
									})}
								>
									Plans
								</Link>
							</li>
							{showMobileMenu && (
								<>
									{userLoggedIn ? (
										<>
											<li
												className={cn({
													"w-full": showMobileMenu,
												})}
											>
												<Link
													className="btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full"
													to="/my-resumes"
												>
													My Resumes
												</Link>
											</li>
											<li
												className={cn({
													"w-full": showMobileMenu,
												})}
											>
												<Link
													className="btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full"
													to="/dashboard"
												>
													Dashboard
												</Link>
											</li>
											<li
												className={cn({
													"w-full": showMobileMenu,
												})}
											>
												<Link
													className="btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full"
													to="/signin"
													onClick={() => {
														removeCookie("Authorization");
														localStorage.removeItem("profileData");
														setUserData(null);
														window.location.href = "/signin";
													}}
												>
													Logout
												</Link>
											</li>
										</>
									) : (
										<>
											<li
												className={cn({
													"w-full": showMobileMenu,
												})}
											>
												<Link
													to="/resume/select-experience"
													className="btn bg-transparent hover:text-primary-600 text-primary-600 font-semibold w-full"
												>
													Create Resume
												</Link>
											</li>
											<li
												className={cn({
													"w-full": showMobileMenu,
												})}
											>
												<Link
													className="btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full"
													to="/signin"
												>
													Log In
												</Link>
											</li>
											<li
												className={cn({
													"w-full": showMobileMenu,
												})}
											>
												<Link
													className="btn text-gray-800 font-medium hover:bg-primary-100 hover:text-primary-700 w-full"
													to="/register"
												>
													Register
												</Link>
											</li>
										</>
									)}
								</>
							)}
						</ul>
					</div>

					<button className="btn bg-primary-500 ml-auto block md:hidden menu-open text-white" onClick={toggleMenu}>
						{!showMobileMenu ? <span className="fa fa-bars" /> : <span className="fa fa-times" />}
					</button>

					<div className="hidden md:flex items-center">
						<Link to="/resume/select-experience" className="btn text-white hover:text-white bg-primary-600">
							Create Resume
						</Link>
						<div className="lg:ml-3 relative">
							<button
								className="btn border-0 text-primary-600 font-medium hover:text-primary-500"
								type="button"
								onClick={() => setOpen(!open)}
							>
								{userLoggedIn ? "My Resumes" : "Login"}
							</button>
							{open && (
								<div className="absolute bg-white !shadow p-2 !rounded-sm z-50" ref={dropdownRef}>
									{userLoggedIn ? (
										<>
											<Link
												className="flex cursor-pointer items-center !rounded p-2 hover:bg-gray-100/80 min-w-[120px] text-gray-700 hover:text-gray-700"
												to="/my-resumes"
											>
												My Resumes
											</Link>
											<Link
												className="flex cursor-pointer items-center !rounded p-2 hover:bg-gray-100/80 min-w-[120px] text-gray-700 hover:text-gray-700"
												to="/dashboard"
											>
												Dashboard
											</Link>
											<Link
												className="flex cursor-pointer items-center !rounded p-2 hover:bg-gray-100/80 min-w-[120px] text-gray-700 hover:text-gray-700"
												to="/signin"
												onClick={() => {
													removeCookie("Authorization");
													localStorage.removeItem("profileData");
													setUserData(null);
													window.location.href = "/signin";
												}}
											>
												Logout
											</Link>
										</>
									) : (
										<>
											<Link
												className="flex cursor-pointer items-center !rounded p-2 hover:bg-gray-100/80 min-w-[120px] text-gray-700 hover:text-gray-700"
												to="/signin"
											>
												Log In
											</Link>
											<Link
												className="flex cursor-pointer items-center !rounded p-2 hover:bg-gray-100/80 min-w-[120px] text-gray-700 hover:text-gray-700"
												to="/register"
											>
												Register
											</Link>
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
