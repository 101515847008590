import { useQuery } from "@tanstack/react-query";
import { Cookies } from "react-cookie";
import { decodeToken } from "react-jwt";

import { API } from "../apiService";

export function useCompanies() {
	return useQuery({
		queryKey: ["companies"],
		queryFn: async () => await API.get("companies"),
		staleTime: Infinity,
	});
}

export function useCompany(id) {
	return useQuery({
		queryKey: ["companies", id],
		queryFn: async () => await API.get(`companies?companyId=${id}`),
		staleTime: Infinity,
	});
}

export function useCompanyJobs(id) {
	return useQuery({
		queryKey: ["companies", id, "jobs"],
		queryFn: async () => await API.get(`/jobs/company?companyId=${id}`),
		staleTime: 86400000,
	});
}

export function useJobCategories() {
	return useQuery({
		queryKey: ["jobs", "categories"],
		queryFn: async () => await API.get("jobs/categories"),
		staleTime: Infinity,
	});
}

export function useLatestJobs() {
	return useQuery({
		queryKey: ["jobs", "latest"],
		queryFn: async () => await API.get("jobs/latest"),
		staleTime: 60 * 1000,
	});
}

export function useUpcomingJobs() {
	return useQuery({
		queryKey: ["jobs", "upcoming"],
		queryFn: async () => await API.get("jobs/upcoming"),
		staleTime: 60 * 1000,
	});
}

export function useFeaturedEmployers() {
	return useQuery({
		queryKey: ["employers", "featured"],
		queryFn: async () => await API.get("jobs/employers/featured"),
		staleTime: 60 * 1000,
	});
}

const cookie = new Cookies();
const auth = cookie.get("Authorization")
	? cookie.get("Authorization").replaceAll("GO::", "").replaceAll("CO::", "")
	: null;

const isExpired = auth ? decodeToken(auth).exp < Date.now() / 1000 : true;

export function useRecommendedJobs() {
	return useQuery({
		queryKey: ["jobs", "recommended"],
		queryFn: async () => await API.get("jobs/recommended"),
		staleTime: 86400 * 1000,
		enabled: !isExpired,
		retry: 0,
	});
}

export function useSavedJobs() {
	return useQuery({
		queryKey: ["jobs", "saved"],
		queryFn: async () => await API.get("jobs/saved"),
		retry: 0,
		staleTime: 60 * 1000,
		enabled: !isExpired,
	});
}

export function useUserAccount() {
	return useQuery({
		queryKey: ["user", "account"],
		queryFn: async () => {
			const { data } = await API.get("users/account");
			return data;
		},
		retry: 0,
		enabled: !isExpired,
	});
}
