import React, { useEffect, useRef, useState } from "react";

import { cn } from "../../handlers/cn.utils";

const SearchableSelectInput = ({ options, onSelect, value, defaultLabel }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);

	const filteredOptions = options?.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isOpen]);

	return (
		<div ref={dropdownRef} className="relative w-full">
			<div
				onClick={toggleDropdown}
				className="cursor-pointer flex h-12 items-center justify-between rounded-md border px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full border-gray-400 bg-white text-gray-800"
				style={{ padding: 8 }}
			>
				{value
					? options.find((item) => item.value === value)?.label || defaultLabel || ""
					: defaultLabel
						? defaultLabel
						: "Select an option"}
				<i className="bi bi-chevron-down ml-5" />
			</div>
			{isOpen && (
				<div className="absolute z-10 mt-1 rounded-md bg-white shadow-lg border border-gray-400 w-full">
					<input
						type="text"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						className="w-full border-none p-2 outline-none focus:ring-0"
						placeholder="Search..."
					/>
					<ul className="max-h-60 overflow-y-auto">
						{filteredOptions.length > 0 ? (
							filteredOptions.map((option, index) => (
								<li
									key={index}
									className={cn("cursor-pointer p-2 text-left hover:bg-gray-200", {
										"text-gray-200": option.disabled,
										"text-gray-900": !option.disabled,
										"bg-gray-100": value === option.value,
									})}
									onClick={() => {
										onSelect(option.value);
										setSearchTerm("");
										setIsOpen(false);
									}}
								>
									<span className="d-flex items-center space-x-2">
										<span>
											<span
												className={cn("bi bi-check-circle-fill mr-2", {
													"text-gray-950": value === option.value,
													"text-white": value !== option.value,
												})}
											/>
											{option.icon && <span className={`bi bi-${option.icon} mx-2`} />}
										</span>
										<span>{option.label}</span>
									</span>
								</li>
							))
						) : (
							<li className="p-2 text-gray-600">No results found</li>
						)}
					</ul>
				</div>
			)}
		</div>
	);
};

export default SearchableSelectInput;
