/* eslint-disable unused-imports/no-unused-vars */
import React from "react";

import { cn } from "../../../handlers/cn.utils";

function MultiSelectItems({ onClick, data }) {
	return (
		<div className="flex gap-2">
			{data.map((item, index) => (
				<div
					className="flex items-center justify-between rounded-sm px-1.5 gap-1 text-sm bg-gray-100"
					key={index}
					onClick={() => onClick(item)}
				>
					<span className="text-nowrap">{item}</span>
					<span className="cursor-pointer h-2 w-2 flex items-center justify-center">
						<i className="fa-solid fa-x text-[6px] text-gray-700" />
					</span>
				</div>
			))}
		</div>
	);
}

function Dropdown({ value, placeholder, onChange, options, multiple = false, search = false }) {
	const [open, setOpen] = React.useState(false);
	const dropdownRef = React.useRef(null);

	const handleOutsideClick = (e) => {
		if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
			setOpen(false);
		}
	};

	React.useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	const onSelect = (item) => {
		onChange(item);
	};

	const isEmptyValue = (value) => {
		return !value || (Array.isArray(value) && value.length === 0);
	};

	const [filteredOptions, setFilteredOptions] = React.useState(options);
	const [searchTerm, setSearchTerm] = React.useState("");

	React.useEffect(() => {
		if (searchTerm) {
			setFilteredOptions(options.filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase())));
		} else {
			setFilteredOptions(options);
		}
	}, [searchTerm, options]);

	return (
		<div className="relative">
			<div
				className={cn(
					"border cursor-pointer border-gray-200 flex items-center h-9 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A]",
					{
						"outline-none ring-2 ring-primary-500 ring-offset-2": open,
					},
				)}
				onClick={() => setOpen(!open)}
			>
				<div className="flex justify-between items-center w-full overflow-hidden">
					<span
						className={cn({
							"text-[#7A7A7A]": !value,
						})}
					>
						{!isEmptyValue(value) ? (
							<>
								{multiple ? (
									<MultiSelectItems
										data={value}
										onClick={(item) => {
											onSelect(item);
											setOpen(false);
										}}
									/>
								) : (
									value
								)}
							</>
						) : (
							placeholder
						)}
					</span>
					<span>
						<i className={`fa-solid fa-chevron-down text-[8px] transform ${open ? "rotate-180" : ""}`} />
					</span>
				</div>
			</div>
			{open && (
				<div
					className="absolute bg-white !shadow !px-2 !py-1 !rounded-sm z-50 mt-2 w-full"
					style={{
						maxHeight: "300px",
						overflowY: "auto",
						overflowX: "hidden",
					}}
					ref={dropdownRef}
				>
					{search && (
						<input
							type="text"
							className="border border-gray-200 h-9 mb-2 text-sm placeholder:text-sm rounded px-2 py-1 placeholder:text-[#7A7A7A] w-full"
							placeholder="Search"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					)}
					{filteredOptions.map((item, index) => (
						<div
							key={index}
							className="flex cursor-pointer items-center !rounded p-2 hover:bg-gray-100/80"
							onClick={() => {
								onSelect(item);
								setOpen(false);
							}}
						>
							{multiple ? (
								<>
									<input type="checkbox" className="!mr-2 h-4 w-4" checked={value.includes(item)} readOnly />
								</>
							) : (
								<span>
									<i
										className={cn("fa-solid fa-circle-check text-sm text-black !mr-2", {
											"text-transparent": multiple ? !value.includes(item) : value !== item,
										})}
									/>
								</span>
							)}
							<span className="text-left text-sm">{item}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default Dropdown;
