import React from "react";
import { Link } from "react-router-dom";

import Icon from "../../../components/Icon";

function AuthLayout({ children }) {
	return (
		<section className="grid grid-cols-1 md:grid-cols-2 h-screen">
			<div className="bg-[#F0F7FF] hidden p-14 md:flex items-center justify-center h-screen">
				<div>
					<Link to="/">
						<img
							src="/images/logo.png"
							alt="DProz Logo"
							className="p-2 mb-4"
							style={{
								width: "180px",
							}}
						/>
					</Link>
					<h1 className="text-[32px] text-gray-700 font-bold font-plus-jakarta">Elevate Your Job Search Success</h1>
					<div className="flex gap-8 my-10 items-center text-xl">
						<span className="text-[#161616]">
							<Icon size={48} iconName="Time" />
						</span>
						<div className="">
							<p className="font-bold text-xl">Save Time:</p>
							<p className="!text-[#161616] text-md">Apply only to jobs that match you best.</p>
						</div>
					</div>
					<div className="flex gap-8 my-10 items-center text-xl">
						<span className="text-[#161616]">
							<Icon size={48} iconName="Job" />
						</span>
						<div className="">
							<p className="font-bold text-xl">Apply More, Search Less:</p>
							<p className="!text-[#161616] text-md">Get personalized job opportunities on your dashboard.</p>
						</div>
					</div>
					<div className="flex gap-8 my-10 items-center text-xl">
						<span className="text-[#161616]">
							<Icon size={48} iconName="Frame" />
						</span>
						<p className="!text-[#161616]">
							<div className="">
								<p className="font-bold text-xl">Never Miss a Chance:</p>
								<p className="!text-[#161616] text-md">
									We track job details for you—no more losing opportunities from billboards or newspapers.
								</p>
							</div>
						</p>
					</div>
				</div>
			</div>
			<div className="bg-white px-16 md:h-screen overflow-y-scroll">{children}</div>
		</section>
	);
}

export default AuthLayout;
