import "../css/global.css";
import "../css/home.css";
import "../css/responsive.css";

import React from "react";
import { Link } from "react-router-dom";

import SVGPattern from "./SVGPattern";

function Footer() {
	return (
		<footer className="relative bg-gray-900 text-gray-300 py-20 px-4 md:px-8 overflow-hidden">
			<div className="absolute inset-0 opacity-15">
				<SVGPattern style={{ width: "100%", height: "100%", transform: "scale(1.5)" }} />
			</div>
			<div className="relative z-10 max-w-6xl mx-auto">
				<div className="grid grid-cols-1 md:grid-cols-4 gap-12">
					<div>
						<h3 className="text-xl font-semibold mb-5 font-plus-jakarta text-white">About Dproz</h3>
						<ul className="space-y-2 font-inter">
							<li>
								<Link href="/about-us" className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5">
									About Us
								</Link>
							</li>
							{/* <li>
								<Link href="/contact" className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5">
									Contact Us
								</Link>
							</li>
							<li>
								<Link href="/careers" className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5">
									Careers at Dproz
								</Link>
							</li> */}
						</ul>
					</div>
					<div>
						<h3 className="text-xl font-semibold mb-5 text-white">For Job Seekers</h3>
						<ul className="space-y-2">
							<li>
								<Link to="/jobs" className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5">
									Browse Jobs
								</Link>
							</li>
							<li>
								<Link to="/companies" className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5">
									Companies
								</Link>
							</li>
							<li>
								<Link
									to="/blog/job-hunt-challenges"
									className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5"
								>
									Career Resources
								</Link>
							</li>
						</ul>
					</div>
					<div>
						<h3 className="text-xl font-semibold mb-5 text-white">For Employers</h3>
						<ul className="space-y-2">
							<li>
								<Link
									to="https://hiring.dproz.com/share-job-description"
									target="_blank"
									className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5"
								>
									Post a Job
								</Link>
							</li>
							<li>
								<Link to="/payments" className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5">
									Pricing
								</Link>
							</li>
							<li>
								<Link
									to="https://hiring.dproz.com/how-it-works"
									target="_blank"
									className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5"
								>
									Employer Resources
								</Link>
							</li>
						</ul>
					</div>
					<div>
						<h3 className="text-xl font-semibold mb-5 text-white">Resources</h3>
						<ul className="space-y-2">
							<li>
								<Link to="/blog" className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5">
									Blog
								</Link>
							</li>
							<li>
								<Link to="/faqs" className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5">
									FAQs
								</Link>
							</li>
							<li>
								<Link
									to="/privacy-policy"
									className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5"
								>
									Privacy Policy
								</Link>
							</li>
							<li>
								<Link
									to="/terms-and-conditions"
									className="inline-block hover:text-primary-400 transition-colors text-sm py-1.5"
								>
									Terms of Service
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="mt-12 text-xs text-gray-500">
					<p>&copy; 2024 TE-VENTURES INC LTD. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
