import { cva } from "class-variance-authority";
import React from "react";

import { cn } from "../../handlers/cn.utils";

const badgeVariants = cva(
	"inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
	{
		variants: {
			variant: {
				default: "bg-primary-100 text-primary-800 border border-primary-200",
				secondary: "bg-gray-100 text-gray-800 border border-gray-200",
				destructive: "bg-red-100 text-red-800 border border-red-200",
				outline: "text-foreground border border-input",
				success: "bg-green-100 text-green-800 border border-green-200",
				warning: "bg-yellow-100 text-yellow-800 border border-yellow-200",
				info: "bg-blue-100 text-blue-800 border border-blue-200",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

const Badge = React.forwardRef((props, ref) => {
	const { className, variant, ...rest } = props;
	return <div ref={ref} className={cn(badgeVariants({ variant }), className)} {...rest} />;
});
Badge.displayName = "Badge";

export { Badge, badgeVariants };
