import "crypto-js/lib-typedarrays";

import React from "react";
import { Link } from "react-router-dom";

import AuthLayout from "../layout/AuthLayout";
import SigninForm from "./SigninForm";

function SigninScreen() {
	return (
		<AuthLayout>
			<div className="py-12 flex flex-col h-full justify-center">
				<Link to="/" className="block md:hidden">
					<img
						src="/images/logo.png"
						alt="DProz Logo"
						className="p-2 mb-8 mx-auto"
						style={{
							width: "180px",
						}}
					/>
				</Link>
				<div className="mb-6">
					<h3 className="font-bold font-plus-jakarta text-xl">Log in to Discover Your Latest Tailored Jobs!</h3>
					<p className="text-[#5C5C5C] text-base font-inter">
						Keep your preferences updated to never miss the perfect opportunity!
					</p>
				</div>
				<SigninForm />
			</div>
		</AuthLayout>
	);
}

export default SigninScreen;
